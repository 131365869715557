import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError, switchMap } from 'rxjs/operators';
import {
  registrationFailed,
  registrationSucceeded,
  registrationRequested,
  loginSucceeded,
  loginFailed,
} from 'actions/forms';
import { REGISTRATION_REQUESTED, LOGIN_REQUESTED, FETCH_USERS_DATA_REQUESTED, FETCH_VENDORS_DATA_REQUESTED } from 'constants/actions';
import * as actionType from 'constants/actions';
import { API_ENDPOINT } from 'constants/api';
import { fetchUsersDataSucceeded, fetchUsersDataFailed, fetchVendorsDataSucceeded, fetchVendorsDataFailed } from 'actions/data';
import * as dataActions from 'actions/data';
import * as authActions from 'actions/auth';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import * as moment from 'moment';
import { getDateRange, getFormatDate, getFormatFloatValues, getSerialize, getTotalIncomeOrExpenseAmount } from 'utils/helpers';
import { DecimalNumber } from 'utils/constants';

//------------------------------------------Users-----------------------------------------
export const fetchUsersDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_USERS_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {

    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/users/download/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            let fileName;
            let { between, area } = action.payload;
            if (between) {
              between = between.split(',');
            }
            if (area && between) {
              fileName = `Users (${area}) Order Placed ${moment(between[0]).format("MMMM Do YYYY")} to ${moment(between[1]).format("MMMM Do YYYY")}.xlsx`;
            } else if (between) {
              fileName = `Users Order Placed ${moment(between[0]).format("MMMM Do YYYY")} to ${moment(between[1]).format("MMMM Do YYYY")}.xlsx`;
            } else if (area) {
              fileName = `Users (${area}).xlsx`;
            } else {
              fileName = `All Users.xlsx`;
            }

            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();
            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Full Name', key: 'fullName', width: 30, style: { font: { bold: true }, alignment: { horizontal: 'center' } } },
              { header: 'Email', key: 'email', width: 25, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Phone', key: 'phone', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Total Order Placed', key: 'totalOrderPlaced', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Total Paid Amount', key: 'totalPaidAmount', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Address', key: 'address', width: 85, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
              { header: 'Joined At', key: 'joinedAt', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
            ];
            ws.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            response.response.rows.forEach(((user, index) => {
              let sum = 0;
              user.orders.forEach((order) => {
                sum += order.paidAmount;
              })
              ws.addRow([
                index + 1,
                `${user.firstName || ''} ${user.lastName || ''}`,
                `${user.email || ''}`,
                `${user.phone || ''}`,
                `${user.orders.length || ''}`,
                `${sum || ''}`,
                `${user.address || ''}`,
                `${moment(user.createdAt).format('DD-MM-YYYY h:mm A') || ''}`,
              ]).font = { bold: false };
            }));
            const buf = await wb.xlsx.writeBuffer();
            const blob = new Blob([buf], { type: "application/octet-stream" });
            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          Download();
          return dataActions.fetchUsersDataDownloadSucceeded();

        }
        return dataActions.fetchUsersDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchUsersDataFailed(error))),
    )
  }),
);

export const fetchProductWiseUsersDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    const payload = Object.assign({}, action.payload);
    delete action.payload.name;
    return ajax.get(`${API_ENDPOINT}/users/search-by-product/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            const { between, name } = payload;
            const date = between.split(',');
            const fileName = `${name} Purchased by Users ${moment(date[0]).format('DD-MM-YYYY')} to ${moment(date[1]).format('DD-MM-YYYY')}.xlsx`;
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();
            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Full Name', key: 'fullName', width: 30, style: { font: { bold: true }, alignment: { horizontal: 'center' } } },
              { header: 'Email', key: 'email', width: 25, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Phone', key: 'phone', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Address', key: 'address', width: 85, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
              { header: 'Order Placed', key: 'orderPlaced', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Joined At', key: 'joinedAt', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
            ];
            ws.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            response.response.forEach(((user, index) => {
              ws.addRow([
                index + 1,
                `${user.firstName || ''} ${user.lastName || ''}`,
                `${user.email || ''}`,
                `${user.phone || ''}`,
                `${user.address || ''}`,
                `${user.orderCount || ''}`,
                `${moment(user.createdAt).format('DD-MM-YYYY h:mm A') || ''}`,
              ]).font = { bold: false };
            }));
            const buf = await wb.xlsx.writeBuffer();
            const blob = new Blob([buf], { type: "application/octet-stream" });
            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          Download();
          return dataActions.fetchProductWiseUsersDataDownloadSucceeded();

        }
        return dataActions.fetchUsersDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchUsersDataFailed(error))),
    )
  }),
);

export const fetchUsersDataEpic = (action$, store$) => action$.pipe(
  ofType(FETCH_USERS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    // const qs = action.payload && action.payload.type ? '?type=admin' : '';
    return ajax.get(`${API_ENDPOINT}/users/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return fetchUsersDataSucceeded(response.response);
        }
        return fetchUsersDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(fetchUsersDataFailed(error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSingleUserDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_USER_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/users/${action.payload.id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleUserDataSucceeded(response.response);
        }
        return dataActions.fetchSingleUserDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleUserDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchUserByPhoneNumberEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_USER_BY_PHONE_NUMBER_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/users/search-by-phone/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchUserByPhoneNumberSucceeded(response.response);
        }
        return dataActions.fetchUserByPhoneNumberFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchUserByPhoneNumberFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------NewsLetter-----------------------------------------
export const fetchNewsLetterEmailsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_NEWSLETTER_EMAILS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    // const qs = action.payload && action.payload.type ? '?type=admin' : '';
    return ajax.get(`${API_ENDPOINT}/users/newsletter-emails/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchNewsLetterEmailsDataSucceeded(response.response);
        }
        return dataActions.fetchNewsLetterEmailsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchNewsLetterEmailsDataFailed(error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchNewsLetterEmailsDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {

    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/users/newsletter-emails/download/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            let fileName = `All Emails.xlsx`;

            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();
            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Email', key: 'email', width: 25, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Subscribed At', key: 'subscribedAt', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
            ];
            ws.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            response.response.rows.forEach(((data, index) => {
              ws.addRow([
                index + 1,
                `${data.email || ''}`,
                `${moment(data.createdAt).format('DD-MM-YYYY h:mm A') || ''}`,
              ]).font = { bold: false };
            }));
            const buf = await wb.xlsx.writeBuffer();
            const blob = new Blob([buf], { type: "application/octet-stream" });
            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          Download();
          return dataActions.fetchNewsLetterEmailsDataDownloadSucceeded();

        }
        return dataActions.fetchNewsLetterEmailsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchNewsLetterEmailsDataFailed(error))),
    )
  }),
);

//------------------------------------------Vendors-----------------------------------------
export const fetchVendorsDataEpic = (action$, store$) => action$.pipe(
  ofType(FETCH_VENDORS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/vendors/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return fetchVendorsDataSucceeded(response.response);
        }
        return fetchVendorsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(fetchVendorsDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchOwnVendorEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_OWN_VENDOR_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/vendor/user-own-vendor/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchOwnVendorSucceeded(response.response);
        }
        return dataActions.fetchOwnVendorFailed('Invalid response from server.');
      }),
      catchError((error) => {
        if (error.response && error.response.statusCode === 401) {
          return of(authActions.logoutRequested());
        }
        console.log("error");
        return of(dataActions.fetchOwnVendorFailed(error));
      }),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Brands-----------------------------------------
export const fetchBrandsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_BRANDS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/brands/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchBrandsDataSucceeded(response.response);
        }
        return dataActions.fetchBrandsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchBrandsDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSingleBrandDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_BRAND_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/brands/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleBrandDataSucceeded(response.response);
        }
        return dataActions.fetchSingleBrandDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleBrandDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Categories-----------------------------------------
export const fetchCategoriesDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_CATEGORIES_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/categories/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchCategoriesDataSucceeded(response.response);
        }
        return dataActions.fetchCategoriesDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchCategoriesDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSingleCategoryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_CATEGORY_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/categories/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleCategoryDataSucceeded(response.response);
        }
        return dataActions.fetchSingleCategoryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleCategoryDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Sub Categories-----------------------------------------
export const fetchSubCategoriesDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SUB_CATEGORIES_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/sub_categories/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSubCategoriesDataSucceeded(response.response);
        }
        return dataActions.fetchSubCategoriesDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSubCategoriesDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSingleSubCategoryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_SUB_CATEGORY_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/sub_categories/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleSubCategoryDataSucceeded(response.response);
        }
        return dataActions.fetchSingleSubCategoryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleSubCategoryDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Products-----------------------------------------
export const fetchProductsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCTS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchProductsDataSucceeded(response.response);
        }
        return dataActions.fetchProductsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchProductsDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchActiveProductsBySearchEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ACTIVE_PRODUCTS_BY_SEARCH_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products/search-active/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchActiveProductBySearchSuccess(response.response);
        }
        return dataActions.fetchActiveProductBySearchFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchActiveProductBySearchFailed(error)))
    )
  }),
);

export const fetchSingleProductDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_PRODUCT_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleProductDataSucceeded(response.response);
        }
        return dataActions.fetchSingleProductDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleProductDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const deleteProductDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_PRODUCT_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/products/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.deleteProductDataSuccessed(response.response);
        }
        return dataActions.deleteProductDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.deleteProductDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchProductsDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCTS_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    let payload = Object.assign({}, action.payload);
    delete action.payload['categoryName'];
    delete action.payload['subCategoryName'];
    return ajax.get(`${API_ENDPOINT}/products/download/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            let fileName;
            if (payload.subCategoryName) {
              fileName = `${payload.subCategoryName}.xlsx`;
            } else if (payload.categoryName) {
              fileName = `${payload.categoryName}.xlsx`;
            } else {
              fileName = `All Products.xlsx`;
            }
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();
            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Name', key: 'name', width: 50, style: { font: { bold: true }, alignment: { horizontal: 'center' } } },
              { header: 'SKU', key: 'sku', width: 25, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Quantity', key: 'quantity', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Buy Price', key: 'buyPrice', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
              { header: 'Sell Price', key: 'sellPrice', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
              { header: 'Discount Price', key: 'discountPrice', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
              { header: 'Total Buying Price', key: 'totalBuyingPrice', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'left' } } },
              { header: 'Total Selling Price', key: 'totalSellingPrice', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Status', key: 'status', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
            ];
            ws.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            response.response.forEach(((product, index) => {

              ws.addRow([
                index + 1,
                `${product.name || ''}`,
                `${product.sku || ''}`,
                `${product.quantity || ''}`,
                `${product.buyPrice || ''}`,
                `${product.sellPrice || ''}`,
                `${product.discountPrice || ''}`,
                `${product.totalBuyingPrice || ''}`,
                `${product.totalDiscountPrice != 0 ? product.totalDiscountPrice : product.totalSellingPrice}`,
                `${product.isActive || ''}`,
              ]).font = { bold: false };
            }));
            const buf = await wb.xlsx.writeBuffer();
            const blob = new Blob([buf], { type: "application/octet-stream" });
            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          Download();
          return dataActions.fetchProductsDataDownloadSucceeded();

        }
        return dataActions.fetchProductsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchProductsDataFailed(error))),
    )
  }),
);

export const fetchProductRequestsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCT_REQUESTS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/requested-products/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchProductRequestsDataSucceeded(response.response);
        }
        return dataActions.fetchProductRequestsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchOrdersDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Stock Products-----------------------------------------
export const fetchStockProductsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_STOCK_PRODUCTS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/stock-products/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchStockProductsDataSucceeded(response.response);
        }
        return dataActions.fetchStockProductsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchStockProductsDataFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchStockProductSinglePurchaseOrSalesDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/stock-products/single-purchase-or-sales/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchStockProductSinglePurchaseOrSalesDataSucceeded(response.response);
        }
        return dataActions.fetchStockProductSinglePurchaseOrSalesDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchStockProductSinglePurchaseOrSalesDataFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const fetchStockProductsPurchaseOrSalesDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/stock-products/purchase-or-sales/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchStockProductsPurchaseOrSalesDataSucceeded(response.response);
        }
        return dataActions.fetchStockProductsPurchaseOrSalesDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchStockProductsPurchaseOrSalesDataFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const fetchStockProductsDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/stock-products/download/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            const fileName = `All Stock Products.xlsx`;

            // Create a new workbook and add a new worksheet
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();

            const style = { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } }

            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style },
              { header: 'Product Name', key: 'name', width: 40, style },
              { header: 'Unit', key: 'weightUnit', width: 20, style },
              { header: 'Stock', key: 'stock', width: 20, style },
              { header: 'Purchase Quantity', key: 'purchaseQuantity', width: 18, style },
              { header: 'Purchase Amount', key: 'purchaseAmount', width: 20, style },
              { header: 'Sales Quantity', key: 'salesQuantity', width: 15, style },
              { header: 'Sales Amount', key: 'salesAmount', width: 15, style }
            ];

            // Set the title and styles
            ws.spliceRows(1, 0, []);
            ws.getCell('A1').value = 'Market Bangla All Stock Products';
            ws.mergeCells('A1: H1');
            ws.getCell('A1').alignment = { horizontal: 'center' };
            ws.getCell('A1').fill = { type: 'pattern', pattern: 'darkVertical', fgColor: { argb: 'FFFF0000' } };

            // Set the header row background color
            const headerCells = ['A2', 'B2', 'C2', 'D2', 'E2', 'F2', 'G2', 'H2'];
            headerCells.forEach((cell) => {
              ws.getCell(cell).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00FF00' } };
            });

            // ws.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            response.response.rows.forEach(((product, index) => {
              const { name, weightUnit, purchaseQuantity, purchaseAmount, salesQuantity, salesAmount } = product;
              ws.addRow([
                index + 1, name, weightUnit, getFormatFloatValues((purchaseQuantity - salesQuantity), DecimalNumber.TWO),
                getFormatFloatValues(purchaseQuantity, DecimalNumber.TWO), getFormatFloatValues(purchaseAmount, DecimalNumber.TWO),
                getFormatFloatValues(salesQuantity, DecimalNumber.TWO), getFormatFloatValues(product.salesAmount, DecimalNumber.TWO)
              ]).font = { bold: false };
            }));

            // Generate a buffer of the Excel file
            const buf = await wb.xlsx.writeBuffer();
            // Create a blob from the buffer
            const blob = new Blob([buf], { type: "application/octet-stream" });
            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);
            // Create a temporary anchor element
            const anchor = document.createElement('a');
            if (anchor.download !== undefined) {
              anchor.style.display = 'none';
              anchor.href = url;
              anchor.download = fileName;
              // Add the anchor to the document body
              document.body.appendChild(anchor);
              // Click the anchor to trigger the download
              anchor.click();
              // Remove the anchor from the document body
              document.body.removeChild(anchor);
            }
          }
          Download();
          return dataActions.fetchStockProductsDataDownloadSucceeded();
        }
        return dataActions.fetchStockProductsDataDownloadFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchStockProductsDataDownloadFailed(error.response.message))),
    )
  }),
);

export const fetchStockProductsPurchaseOrSalesDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/stock-products-purchase-sales/download/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            let totalAmount = 0;
            const { isPurchase, isSale, between } = action.payload;
            const type = isPurchase ? 'purchase' : 'sales';
            const [startDate, endDate] = between.split(",");
            const dateRange = `${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}`;
            const fileType = isPurchase ? 'Purchase' : 'Sales';
            const fileName = isPurchase ? `${fileType} - ${dateRange}.xlsx` : `${fileType} - ${dateRange}.xlsx`;

            // Create a new workbook and add a new worksheet
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();

            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Product Name', key: 'name', width: 30, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Quantity', key: 'quantity', width: 18, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Unit', key: 'weightUnit', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Amount', key: 'amount', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } }
            ];

            // Set the title and styles
            ws.spliceRows(1, 0, []);
            ws.getCell('A1').value = `Market Bangla ${fileType} History`;
            ws.mergeCells('A1: E1');
            ws.getCell('A1').alignment = { horizontal: 'center' };
            ws.getCell('A1').fill = { type: 'pattern', pattern: 'darkVertical', fgColor: { argb: 'FFFF0000' } };

            // Set the header row background color
            const headerCells = ['A2', 'B2', 'C2', 'D2', 'E2'];
            headerCells.forEach((cell) => {
              ws.getCell(cell).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00FF00' } };
            });


            // ws.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            response.response.forEach(((product, index) => {
              totalAmount += product.amount;
              ws.addRow([
                index + 1, product.name, product.quantity, product.weightUnit, product.amount
              ]).font = { bold: false };
            }));

            ws.addRow(['', '', '', '', totalAmount]).font = { bold: true };
            let cellsToBeMerged = ws.lastRow._cells.map(i => i._address);
            cellsToBeMerged.pop();
            ws.mergeCells(`${cellsToBeMerged[0]}:${cellsToBeMerged.pop()}`);
            ws.lastRow._cells[0]._value.model.value = `Total Amount (${dateRange})`;
            ws.lastRow.height = 20;

            // Generate a buffer of the Excel file
            const buf = await wb.xlsx.writeBuffer();
            // Create a blob from the buffer
            const blob = new Blob([buf], { type: "application/octet-stream" });
            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);
            // Create a temporary anchor element
            const anchor = document.createElement('a');
            if (anchor.download !== undefined) {
              anchor.style.display = 'none';
              anchor.href = url;
              anchor.download = fileName;
              // Add the anchor to the document body
              document.body.appendChild(anchor);
              // Click the anchor to trigger the download
              anchor.click();
              // Remove the anchor from the document body
              document.body.removeChild(anchor);
            }
          }
          Download();
          return dataActions.fetchStockProductsPurchaseOrSalesDataDownloadSucceeded();
        }
        return dataActions.fetchStockProductsPurchaseOrSalesDataDownloadFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchStockProductsPurchaseOrSalesDataDownloadFailed(error.response.message))),
    )
  }),
);

export const fetchStockProductsTotalSummaryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/stock-products/total-summary/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchStockProductsTotalSummaryDataSucceeded(response.response);
        }
        return dataActions.fetchStockProductsTotalSummaryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchStockProductsTotalSummaryDataFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const fetchSingleStockProductDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_STOCK_PRODUCT_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/stock-products/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleStockProductDataSucceeded(response.response);
        }
        return dataActions.fetchSingleStockProductDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleStockProductDataFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Packages-----------------------------------------
export const fetchActivePackageProductsEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ACTIVE_PACKAGE_PRODUCTS_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products/search-active/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchActivePackageProductSuccess(response.response);
        }
        return dataActions.fetchActivePackageProductFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchActivePackageProductFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchPackagesDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PACKAGES_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/packages/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchPackagesDataSucceed(response.response);
        }
        return dataActions.fetchPackagesDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchPackagesDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSinglePackageDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_PACKAGE_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/packages/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSinglePackageDataSucceed(response.response);
        }
        return dataActions.fetchSinglePackageDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSinglePackageDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Products Q&A, Reviews-----------------------------------------
export const fetchSingleProductQuestionsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products-questions-data/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleProductQuestionsDataSucceeded(response.response);
        }
        return dataActions.fetchSingleProductQuestionsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleProductQuestionsDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchProductsQuestionsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCTS_QUESTIONS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products-questions-data/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchProductsQuestionsDataSucceeded(response.response);
        }
        return dataActions.fetchProductsQuestionsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchProductsQuestionsDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchProductQuestionCountEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCT_QUESTION_COUNT_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/product-question-count/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchProductQuestionCountSucceeded(response.response);
        }
        return dataActions.fetchProductQuestionCountFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchProductQuestionCountFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchProductReviewsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCT_REVIEWS_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/product-review-rating/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchProductReviewsDataSucceeded(response.response);
        }
        return dataActions.fetchProductReviewsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchProductReviewsDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchProductsReviewsRatingsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products-reviews-ratings/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchProductsReviewsRatingsDataSucceeded(response.response);
        }
        return dataActions.fetchProductsReviewsRatingsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchProductsReviewsRatingsDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Coupons-----------------------------------------
export const fetchCouponsDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_COUPONS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/coupons/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchCouponsDataSucceeded(response.response);
        }
        return dataActions.fetchCouponsDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchCouponsDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchActiveCouponsBySearchEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ACTIVE_COUPONS_BY_SEARCH_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/coupons/search-active/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchActiveCouponBySearchSuccess(response.response);
        }
        return dataActions.fetchActiveCouponBySearchFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchActiveCouponBySearchFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSingleCouponDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_COUPON_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/coupons/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleCouponDataSucceeded(response.response);
        }
        return dataActions.fetchSingleCouponDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleCouponDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Orders-----------------------------------------
export const fetchOrdersDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ORDERS_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/orders/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {


          return dataActions.fetchOrdersDataSucceeded(response.response);
        }
        return dataActions.fetchOrdersDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchOrdersDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchOrdersDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ORDERS_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {

    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/orders/download/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            const dayStart = moment().startOf('day').format('Y-MM-DD');
            let fileName;
            if (action.payload.between == null) {
              fileName = `All Orders ${dayStart}.xlsx`;
              if (action.payload.pageTitle === "Back orders") fileName = `Back orders ${dayStart}.xlsx`;
              else if (action.payload.pageTitle === "Web orders") fileName = `Web order ${dayStart}.xlsx`
            } else {
              let date = action.payload.between.split(",")
              let startDate = date[0].split(" ")
              let endDate = date[1].split(" ")
              fileName = `All Orders ${startDate[0]} to ${endDate[0]}.xlsx`;
              if (action.payload.pageTitle === "Back orders") fileName = `Back orders ${startDate[0]} to ${endDate[0]}.xlsx`;
              else if (action.payload.pageTitle === "Web orders") fileName = `Web orders ${startDate[0]} to ${endDate[0]}.xlsx`
            }

            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();
            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Invoice ID', key: 'invoiceId', width: 20, style: { font: { bold: true }, alignment: { horizontal: 'center' } } },
              { header: 'Name', key: 'name', width: 30, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Phone', key: 'phone', width: 18, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Placed at', key: 'placedAt', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Total Amount', key: 'totalAmount', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Order Status', key: 'orderStatus', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Payment Status', key: 'paymentStatus', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
            ];
            ws.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            response.response.rows.forEach(((order, index) => {
              ws.addRow([
                index + 1, order.invoiceId,
                `${order.user_firstName || ''} ${order.user_lastName || ''}`,
                `${order.user_phone || ''}`,
                `${moment(order.createdAt).format('DD-MM-YYYY h:mm A') || ''}`,
                `${order.totalAmount || ''}`,
                `${order.orderStatus || ''}`,
                `${order.paymentStatus || ''}`,
              ]).font = { bold: false };
            }));
            const buf = await wb.xlsx.writeBuffer();
            const blob = new Blob([buf], { type: "application/octet-stream" });
            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          Download();
          return dataActions.fetchOrdersDataDownloadSucceeded();

        }
        return dataActions.fetchOrdersDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchOrdersDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSingleOrderDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_ORDER_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/orders/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleOrderDataSucceeded(response.response);
        }
        return dataActions.fetchSingleOrderDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleOrderDataFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const downloadOrderInvoiceEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DOWONLOAD_INVOICE_PDF_REQUEST),
  switchMap((action) => {
    const payload = action.payload;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
      'Accept': 'application/pdf',
      'Content-Type': 'application/json;charset=UTF-8'
    };
    // return ajax.post(`${API_ENDPOINT}/orders/get-invoice/`, payload, headers).pipe(
    return ajax({
      url: `${API_ENDPOINT}/orders/get-invoice/`,
      method: 'POST',
      headers,
      body: payload,
      responseType: 'arraybuffer'
    }).pipe(
      map((response) => {
        const { status, response: data } = response;
        if (status === 200) {
          const blob = new Blob([data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${payload.name}.pdf`
          link.click()
          setTimeout(() => {
            link.remove();
          }, 100);
          return dataActions.downloadOrderInvoiceSuccess();
        }
        return dataActions.downloadOrderInvoiceError('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.downloadOrderInvoiceError(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Dashboard-----------------------------------------
export const fetchDashboardDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_DASHBOARD_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/statistics/dashboard/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchDashboardDataSucceeded(response.response);
        }
        return dataActions.fetchDashboardDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchDashboardDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Sales-----------------------------------------
export const fetchAdminSalesReportEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ADMIN_SALES_REPORT_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/statistics/admin-sales-report/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchAdminSalesReportSucceeded(response.response);
        }
        return dataActions.fetchAdminSalesReportFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchAdminSalesReportFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSalesDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SALES_DATA_DATA_REQUESTED),
  switchMap((action) => {

    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/orders/sold-products/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSalesDataDataSuccess(response.response);
        }
        return dataActions.fetchSalesDataDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSalesDataDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Histories-----------------------------------------
export const fetchProductsHistoryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_PRODUCT_HISTORY_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/products-history/${id}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchProductHistoryDataSucceeded(response.response);
        }
        return dataActions.fetchProductHistoryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchProductHistoryDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchCategoryHistoryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_CATEGORY_HISTORY_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/category-history/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchCategoryHistoryDataSucceeded(response.response);
        }
        return dataActions.fetchCategoryHistoryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchCategoryHistoryDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchSubCategoryHistoryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SUB_CATEGORY_HISTORY_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/sub-category-history/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSubCategoryHistoryDataSucceeded(response.response);
        }
        return dataActions.fetchSubCategoryHistoryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSubCategoryHistoryDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchCouponHistoryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_COUPON_HISTORY_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/coupon-history/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchCouponHistoryDataSucceeded(response.response);
        }
        return dataActions.fetchCouponHistoryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchCouponHistoryDataFailed('Invalid response from server.'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const fetchOrderHistoryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ORDER_HISTORY_DATA_REQUESTED),
  switchMap((action) => {
    const id = action.payload.id;
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/orders-history/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchOrderHistoryDataSucceeded(response.response);
        }
        return dataActions.fetchOrderHistoryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchOrderHistoryDataFailed('Invalid response from server.'))),
    )
  }),
);


//Account
export const fetchIncomeExpenseTypeNameDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/income-expense-type-name/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchIncomeExpenseTypeNameDataSucceeded(response.response);
        }
        return dataActions.fetchIncomeExpenseTypeNameDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchIncomeExpenseTypeNameDataFailed('Invalid response from server.')))
    )
  }),
);

export const fetchIncomeOrExpenseDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_INCOME_OR_EXPENSE_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/income-expense/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchIncomeOrExpenseDataSucceeded(response.response);
        }
        return dataActions.fetchIncomeOrExpenseDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchIncomeOrExpenseDataFailed('Invalid response from server.')))
    )
  }),
);

export const fetchIncomeOrExpenseDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/income-expense/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            const { isIncomePage, between } = action.payload;
            const type = isIncomePage ? 'incomes' : 'expenses';
            const [startDate, endDate] = between.split(",");
            const dateRange = getDateRange(startDate, endDate);;
            const fileType = isIncomePage ? 'Income' : 'Expense';
            const fileName = isIncomePage ? `${fileType} - ${dateRange}.xlsx` : `${fileType} - ${dateRange}.xlsx`;

            // Create a new workbook and add a new worksheet
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();

            // Set the column headers
            const columns = isIncomePage ? [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Invoice Id', key: 'invoiceId', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Customer Name', key: 'customerName', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Payment Method', key: 'pamentMethod', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Payment Status', key: 'paymentStatus', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Receive From', key: 'receiveFrom', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Amount', key: 'amount', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Remarks', key: 'remarks', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Date', key: 'date', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
            ] : [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Paid to', key: 'paidTo', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Expense', key: 'expense', width: 25, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Amount', key: 'amount', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Remarks', key: 'remarks', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Date', key: 'date', width: 20, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } }
            ]

            ws.columns = columns;

            // Set the title and styles
            ws.spliceRows(1, 0, []);
            ws.getCell('A1').value = `Market Bangla ${fileType} History`;
            ws.mergeCells(`A1: ${isIncomePage ? 'I1' : 'F1'}`);
            ws.getCell('A1').alignment = { horizontal: 'center' };
            ws.getCell('A1').fill = { type: 'pattern', pattern: 'darkVertical', fgColor: { argb: 'FFFF0000' } };

            // Set the header row background color
            const RowBackground = isIncomePage ? ['A2', 'B2', 'C2', 'D2', 'E2', 'F2', 'G2', 'H2', 'I2'] : ['A2', 'B2', 'C2', 'D2', 'E2', 'F2'];
            const headerCells = RowBackground;
            headerCells.forEach((cell) => {
              ws.getCell(cell).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00FF00' } };
            });

            // Add the data to the worksheet
            if (isIncomePage) {
              response.response.forEach((item, index) => {
                const { invoiceId, customerName, paymentMethod, paymentStatus, expenseIncomeTypeName, remarks, receiveAt, amount } = item;
                const { name } = expenseIncomeTypeName || {};
                ws.addRow([index + 1, invoiceId || 'N/A', customerName || 'N/A', paymentMethod || 'N/A', paymentStatus || 'N/A', name, amount, remarks || 'N/A', getFormatDate(receiveAt)]).font = { bold: false };
              });
            } else {
              response.response.forEach((item, index) => {
                const { invoiceId, payTo, expenseIncomeTypeName, remarks, payAt, amount } = item;
                const { name } = expenseIncomeTypeName || {};
                ws.addRow([index + 1, payTo || 'N/A', name, amount, remarks || 'N/A', getFormatDate(payAt)]).font = { bold: false };
              });
            }

            // Generate a buffer of the Excel file
            const buf = await wb.xlsx.writeBuffer();
            // Create a blob from the buffer
            const blob = new Blob([buf], { type: "application/octet-stream" });
            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);
            // Create a temporary anchor element
            const anchor = document.createElement('a');
            if (anchor.download !== undefined) {
              anchor.style.display = 'none';
              anchor.href = url;
              anchor.download = fileName;
              // Add the anchor to the document body
              document.body.appendChild(anchor);
              // Click the anchor to trigger the download
              anchor.click();
              // Remove the anchor from the document body
              document.body.removeChild(anchor);
            }
          }
          Download();
          return dataActions.fetchIncomeOrExpenseDataDownloadSucceeded(response.response);
        }
        return dataActions.fetchIncomeOrExpenseDataDownloadFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchIncomeOrExpenseDataDownloadFailed('Invalid response from server.')))
    )
  }),
);

export const fetchSingleIncomeOrExpenseDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/single-income-expense/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchSingleIncomeOrExpenseDataSucceeded(response.response);
        }
        return dataActions.fetchSingleIncomeOrExpenseDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchSingleIncomeOrExpenseDataFailed('Invalid response from server.')))
    )
  }),
);


export const deleteIncomeORExpenseEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_INCOME_OR_EXPENSE_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/income-expense/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.deleteIncomeORExpenseSucceeded(response.response.message);
        }
        return dataActions.deleteIncomeORExpenseSucceeded('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.deleteIncomeORExpenseFailed(error.response ? error.response.message : 'error')))
    )
  }),
);


export const fetchIncomeOrExpenseSummaryDataEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/income-expense-summary/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchIncomeOrExpenseSummaryDataSucceeded(response.response);
        }
        return dataActions.fetchIncomeOrExpenseSummaryDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchIncomeOrExpenseSummaryDataFailed('Invalid response from server.')))
    )
  }),
);

export const fetchIncomeOrExpenseSummaryDataDownloadEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/income-expense-summary/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          async function Download() {
            let totalAmount = 0;
            const { isIncomePage, between } = action.payload;
            const type = isIncomePage ? 'incomes' : 'expenses';
            const [startDate, endDate] = between.split(",");
            const dateRange = `${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}`;
            const fileType = isIncomePage ? 'Income' : 'Expense';
            const fileName = isIncomePage ? `${fileType} - ${dateRange}.xlsx` : `${fileType} - ${dateRange}.xlsx`;

            // Create a new workbook and add a new worksheet
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();

            // Set the column headers
            ws.columns = [
              { header: 'SL No.', key: 'sl', width: 10, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } },
              { header: 'Name', key: 'name', width: 30, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'left' } } },
              { header: 'Amount', key: 'amount', width: 15, style: { font: { bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } } }
            ];

            // Set the title and styles
            ws.spliceRows(1, 0, []);
            ws.getCell('A1').value = `Market Bangla Cash Book (${fileType})`;
            ws.mergeCells('A1:C1');
            ws.getCell('A1').alignment = { horizontal: 'center' };
            ws.getCell('A1').fill = { type: 'pattern', pattern: 'darkVertical', fgColor: { argb: 'FFFF0000' } };

            // Set the header row background color
            const headerCells = ['A2', 'B2', 'C2'];
            headerCells.forEach((cell) => {
              ws.getCell(cell).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00FF00' } };
            });

            // Add the data to the worksheet
            response.response.forEach((item, index) => {
              const sum = getTotalIncomeOrExpenseAmount(item, type);
              totalAmount += sum;
              ws.addRow([index + 1, item.name, sum]).font = { bold: false };
            });


            const newRow = ws.addRow(['', '', totalAmount]);
            for (let i = 1; i <= 3; i++) {
              newRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFC6E0B4' },
              };
            }


            let cellsToBeMerged = ws.lastRow._cells.map(i => i._address);;
            cellsToBeMerged.pop();
            ws.mergeCells(`${cellsToBeMerged[0]}:${cellsToBeMerged.pop()}`);
            ws.lastRow._cells[0]._value.model.value = `Total Amount (${dateRange})`;
            ws.lastRow.height = 20;

            // Generate a buffer of the Excel file
            const buf = await wb.xlsx.writeBuffer();
            // Create a blob from the buffer
            const blob = new Blob([buf], { type: "application/octet-stream" });
            // Create a temporary URL for the blob
            const url = URL.createObjectURL(blob);
            // Create a temporary anchor element
            const anchor = document.createElement('a');
            if (anchor.download !== undefined) {
              anchor.style.display = 'none';
              anchor.href = url;
              anchor.download = fileName;
              // Add the anchor to the document body
              document.body.appendChild(anchor);
              // Click the anchor to trigger the download
              anchor.click();
              // Remove the anchor from the document body
              document.body.removeChild(anchor);
            }
          }
          Download();
          return dataActions.fetchIncomeOrExpenseSummaryDataDownloadSucceeded();
        }
        return dataActions.fetchIncomeOrExpenseSummaryDataDownloadFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchIncomeOrExpenseSummaryDataDownloadFailed(error.response.message))),
    )
  }),
);

export const fetchOrderByInvoiceIdEpic = (action$, store$) => action$.pipe(
  ofType(actionType.FETCH_ORDER_BY_INVOICE_ID_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.get(`${API_ENDPOINT}/search-order-by-invoice-id/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return dataActions.fetchOrderByInvoiceIdSucceeded(response.response);
        }
        return dataActions.fetchOrderByInvoiceIdFailed('Invalid response from server.');
      }),
      catchError((error) => of(dataActions.fetchOrderByInvoiceIdFailed(error)))
    )
  }),
);
