export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const REGISTRATION_REQUESTED = 'REGISTRATION_REQUESTED';
export const REGISTRATION_SUCCEEDED = 'REGISTRATION_SUCCEEDED';

//Users
export const FETCH_USERS_DATA_DOWNLOAD_REQUESTED = 'FETCH_USERS_DATA_DOWNLOAD_REQUESTED';
export const FETCH_USERS_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_USERS_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_REQUESTED = 'FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_REQUESTED';
export const FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_USERS_DATA_REQUESTED = 'FETCH_USERS_DATA_REQUESTED';
export const FETCH_USERS_DATA_SUCCEEDED = 'FETCH_USERS_DATA_SUCCEEDED';
export const FETCH_USERS_DATA_FAILED = 'FETCH_USERS_DATA_FAILED';
export const FETCH_SINGLE_USER_DATA_REQUESTED = 'FETCH_SINGLE_USER_DATA_REQUESTED';
export const FETCH_SINGLE_USER_DATA_SUCCEEDED = 'FETCH_SINGLE_USER_DATA_SUCCEEDED';
export const FETCH_SINGLE_USER_DATA_FAILED = 'FETCH_SINGLE_USER_DATA_FAILED';
export const CREATE_ADMIN_DATA_REQUESTED = 'CREATE_ADMIN_DATA_REQUESTED';
export const CREATE_ADMIN_DATA_SUCCEEDED = 'CREATE_ADMIN_DATA_SUCCEEDED';
export const CREATE_ADMIN_DATA_FAILED = 'CREATE_ADMIN_DATA_FAILED';
export const UPDATE_ADMIN_DATA_REQUESTED = 'UPDATE_ADMIN_DATA_REQUESTED';
export const UPDATE_ADMIN_DATA_SUCCEEDED = 'UPDATE_ADMIN_DATA_SUCCEEDED';
export const UPDATE_ADMIN_DATA_FAILED = 'UPDATE_ADMIN_DATA_FAILED';

export const FETCH_NEWSLETTER_EMAILS_DATA_REQUESTED = 'FETCH_NEWSLETTER_EMAILS_DATA_REQUESTED';
export const FETCH_NEWSLETTER_EMAILS_DATA_SUCCEEDED = 'FETCH_NEWSLETTER_EMAILS_DATA_SUCCEEDED';
export const FETCH_NEWSLETTER_EMAILS_DATA_FAILED = 'FETCH_NEWSLETTER_EMAILS_DATA_FAILED';
export const FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_REQUESTED = 'FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_REQUESTED';
export const FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_FAILED = 'FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_FAILED';

export const FETCH_USER_BY_PHONE_NUMBER_REQUESTED = 'FETCH_USER_BY_PHONE_NUMBER_REQUESTED';
export const FETCH_USER_BY_PHONE_NUMBER_SUCCEEDED = 'FETCH_USER_BY_PHONE_NUMBER_SUCCEEDED';
export const FETCH_USER_BY_PHONE_NUMBER_FAILED = 'FETCH_USER_BY_PHONE_NUMBER_FAILED';

//Vendors
export const FETCH_VENDORS_DATA_FAILED = 'FETCH_VENDORS_DATA_FAILED';
export const FETCH_VENDORS_DATA_REQUESTED = 'FETCH_VENDORS_DATA_REQUESTED';
export const FETCH_VENDORS_DATA_SUCCEEDED = 'FETCH_VENDORS_DATA_SUCCEEDED';
export const CREATE_VENDOR_FAILED = 'CREATE_VENDOR_FAILED';
export const CREATE_VENDOR_REQUESTED = 'CREATE_VENDOR_REQUESTED';
export const CREATE_VENDOR_SUCCEEDED = 'CREATE_VENDOR_SUCCEEDED';
export const UPDATE_VENDOR_FAILED = 'UPDATE_VENDOR_FAILED';
export const UPDATE_VENDOR_REQUESTED = 'UPDATE_VENDOR_REQUESTED';
export const UPDATE_VENDOR_SUCCEEDED = 'UPDATE_VENDOR_SUCCEEDED';
export const FETCH_OWN_VENDOR_REQUESTED = 'FETCH_OWN_VENDOR_REQUESTED';
export const FETCH_OWN_VENDOR_FAILED = 'FETCH_OWN_VENDOR_FAILED';
export const FETCH_OWN_VENDOR_SUCCEEDED = 'FETCH_OWN_VENDOR_SUCCEEDED';

//Brands
export const FETCH_BRANDS_DATA_REQUESTED = 'FETCH_BRANDS_DATA_REQUESTED';
export const FETCH_BRANDS_DATA_SUCCEEDED = 'FETCH_BRANDS_DATA_SUCCEEDED';
export const FETCH_BRANDS_DATA_FAILED = 'FETCH_BRANDS_DATA_FAILED';
export const FETCH_SINGLE_BRAND_DATA_REQUESTED = 'FETCH_SINGLE_BRAND_DATA_REQUESTED';
export const FETCH_SINGLE_BRAND_DATA_SUCCEEDED = 'FETCH_SINGLE_BRAND_DATA_SUCCEEDED';
export const FETCH_SINGLE_BRAND_DATA_FAILED = 'FETCH_SINGLE_BRAND_DATA_FAILED';
export const CREATE_BRAND_REQUESTED = 'CREATE_BRAND_REQUESTED';
export const CREATE_BRAND_SUCCEEDED = 'CREATE_BRAND_SUCCEEDED';
export const CREATE_BRAND_FAILED = 'CREATE_BRAND_FAILED';
export const UPDATE_BRAND_REQUESTED = 'UPDATE_BRAND_REQUESTED';
export const UPDATE_BRAND_SUCCEEDED = 'UPDATE_BRAND_SUCCEEDED';
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED';

//Categories
export const FETCH_CATEGORIES_DATA_REQUESTED = 'FETCH_CATEGORIES_DATA_REQUESTED';
export const FETCH_CATEGORIES_DATA_SUCCEEDED = 'FETCH_CATEGORIES_DATA_SUCCEEDED';
export const FETCH_CATEGORIES_DATA_FAILED = 'FETCH_CATEGORIES_DATA_FAILED';
export const FETCH_SINGLE_CATEGORY_DATA_REQUESTED = 'FETCH_SINGLE_CATEGORY_DATA_REQUESTED';
export const FETCH_SINGLE_CATEGORY_DATA_SUCCEEDED = 'FETCH_SINGLE_CATEGORY_DATA_SUCCEEDED';
export const FETCH_SINGLE_CATEGORY_DATA_FAILED = 'FETCH_SINGLE_CATEGORY_DATA_FAILED';
export const CREATE_CATEGORY_REQUESTED = 'CREATE_CATEGORY_REQUESTED';
export const CREATE_CATEGORY_SUCCEEDED = 'CREATE_CATEGORY_SUCCEEDED';
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';
export const UPDATE_CATEGORY_REQUESTED = 'UPDATE_CATEGORY_REQUESTED';
export const UPDATE_CATEGORY_SUCCEEDED = 'UPDATE_CATEGORY_SUCCEEDED';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';
export const DELETE_CATEGORY_BRAND_REQUESTED = 'DELETE_CATEGORY_BRAND_REQUESTED';
export const DELETE_CATEGORY_BRAND_SUCCEEDED = 'DELETE_CATEGORY_BRAND_SUCCEEDED';
export const DELETE_CATEGORY_BRAND_FAILED = 'DELETE_CATEGORY_BRAND_FAILED';

//Packages
export const FETCH_PACKAGES_DATA_REQUESTED = 'FETCH_PACKAGES_DATA_REQUESTED';
export const FETCH_PACKAGES_DATA_SUCCEEDED = 'FETCH_PACKAGES_DATA_SUCCEEDED';
export const FETCH_PACKAGES_DATA_FAILED = 'FETCH_PACKAGES_DATA_FAILED';
export const FETCH_SINGLE_PACKAGE_DATA_REQUESTED = 'FETCH_SINGLE_PACKAGE_DATA_REQUESTED';
export const FETCH_SINGLE_PACKAGE_DATA_SUCCEEDED = 'FETCH_SINGLE_PACKAGE_DATA_SUCCEEDED';
export const FETCH_SINGLE_PACKAGE_DATA_FAILED = 'FETCH_SINGLE_PACKAGE_DATA_FAILED';
export const CREATE_PACKAGE_REQUESTED = 'CREATE_PACKAGE_REQUESTED';
export const CREATE_PACKAGE_SUCCEEDED = 'CREATE_PACKAGE_SUCCEEDED';
export const CREATE_PACKAGE_FAILED = 'CREATE_PACKAGE_FAILED';
export const UPDATE_PACKAGE_REQUESTED = 'UPDATE_PACKAGE_REQUESTED';
export const UPDATE_PACKAGE_SUCCEEDED = 'UPDATE_PACKAGE_SUCCEEDED';
export const UPDATE_PACKAGE_FAILED = 'UPDATE_PACKAGE_FAILED';

//Sub Categories
export const FETCH_SUB_CATEGORIES_DATA_REQUESTED = 'FETCH_SUB_CATEGORIES_DATA_REQUESTED';
export const FETCH_SUB_CATEGORIES_DATA_SUCCEEDED = 'FETCH_SUB_CATEGORIES_DATA_SUCCEEDED';
export const FETCH_SUB_CATEGORIES_DATA_FAILED = 'FETCH_SUB_CATEGORIES_DATA_FAILED';
export const FETCH_SINGLE_SUB_CATEGORY_DATA_REQUESTED = 'FETCH_SINGLE_SUB_CATEGORY_DATA_REQUESTED';
export const FETCH_SINGLE_SUB_CATEGORY_DATA_SUCCEEDED = 'FETCH_SINGLE_SUB_CATEGORY_DATA_SUCCEEDED';
export const FETCH_SINGLE_SUB_CATEGORY_DATA_FAILED = 'FETCH_SINGLE_SUB_CATEGORY_DATA_FAILED';
export const CREATE_SUB_CATEGORY_REQUESTED = 'CREATE_SUB_CATEGORY_REQUESTED';
export const CREATE_SUB_CATEGORY_SUCCEEDED = 'CREATE_SUB_CATEGORY_SUCCEEDED';
export const CREATE_SUB_CATEGORY_FAILED = 'CREATE_SUB_CATEGORY_FAILED';
export const UPDATE_SUB_CATEGORY_REQUESTED = 'UPDATE_SUB_CATEGORY_REQUESTED';
export const UPDATE_SUB_CATEGORY_SUCCEEDED = 'UPDATE_SUB_CATEGORY_SUCCEEDED';
export const UPDATE_SUB_CATEGORY_FAILED = 'UPDATE_SUB_CATEGORY_FAILED';

//Products
export const FETCH_PRODUCTS_DATA_REQUESTED = 'FETCH_PRODUCTS_DATA_REQUESTED';
export const FETCH_PRODUCTS_DATA_SUCCEEDED = 'FETCH_PRODUCTS_DATA_SUCCEEDED';
export const FETCH_PRODUCTS_DATA_FAILED = 'FETCH_PRODUCTS_DATA_FAILED';
export const FETCH_SINGLE_PRODUCT_DATA_REQUESTED = 'FETCH_SINGLE_PRODUCT_DATA_REQUESTED';
export const FETCH_SINGLE_PRODUCT_DATA_SUCCEEDED = 'FETCH_SINGLE_PRODUCT_DATA_SUCCEEDED';
export const FETCH_SINGLE_PRODUCT_DATA_FAILED = 'FETCH_SINGLE_PRODUCT_DATA_FAILED';
export const FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_REQUESTED = 'FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_REQUESTED';
export const FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_SUCCEEDED = 'FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_SUCCEEDED';
export const FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_FAILED = 'FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_FAILED';
export const FETCH_PRODUCTS_QUESTIONS_DATA_REQUESTED = 'FETCH_PRODUCTS_QUESTIONS_DATA_REQUESTED';
export const FETCH_PRODUCTS_QUESTIONS_DATA_SUCCEEDED = 'FETCH_PRODUCTS_QUESTIONS_DATA_SUCCEEDED';
export const FETCH_PRODUCTS_QUESTIONS_DATA_FAILED = 'FETCH_PRODUCTS_QUESTIONS_DATA_FAILED';
export const FETCH_PRODUCT_QUESTION_COUNT_REQUESTED = 'FETCH_PRODUCT_QUESTION_COUNT_REQUESTED';
export const FETCH_PRODUCT_QUESTION_COUNT_SUCCEEDED = 'FETCH_PRODUCT_QUESTION_COUNT_SUCCEEDED';
export const FETCH_PRODUCT_QUESTION_COUNT_FAILED = 'FETCH_PRODUCT_QUESTION_COUNT_FAILED';
export const FETCH_PRODUCT_REVIEWS_DATA_REQUESTED = 'FETCH_PRODUCT_REVIEWS_DATA_REQUESTED';
export const FETCH_PRODUCT_REVIEWS_DATA_SUCCEEDED = 'FETCH_PRODUCT_REVIEWS_DATA_SUCCEEDED';
export const FETCH_PRODUCT_REVIEWS_DATA_FAILED = 'FETCH_PRODUCT_REVIEWS_DATA_FAILED';
export const FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_REQUESTED = 'FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_REQUESTED';
export const FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_SUCCEEDED = 'FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_SUCCEEDED';
export const FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_FAILED = 'FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_FAILED';
export const CREATE_PRODUCT_REQUESTED = 'CREATE_PRODUCT_REQUESTED';
export const CREATE_PRODUCT_SUCCEEDED = 'CREATE_PRODUCT_SUCCEEDED';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';
export const UPDATE_PRODUCT_REQUESTED = 'UPDATE_PRODUCT_REQUESTED';
export const UPDATE_PRODUCT_SUCCEEDED = 'UPDATE_PRODUCT_SUCCEEDED';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';
export const UPDATE_PRODUCT_ANSWER_REQUESTED = 'UPDATE_PRODUCT_ANSWER_REQUESTED';
export const UPDATE_PRODUCT_ANSWER_SUCCEEDED = 'UPDATE_PRODUCT_ANSWER_SUCCEEDED';
export const UPDATE_PRODUCT_ANSWER_FAILED = 'UPDATE_PRODUCT_ANSWER_FAILED';
export const UPDATE_PRODUCT_REVIEW_REQUESTED = 'UPDATE_PRODUCT_REVIEW_REQUESTED';
export const UPDATE_PRODUCT_REVIEW_SUCCEEDED = 'UPDATE_PRODUCT_REVIEW_SUCCEEDED';
export const UPDATE_PRODUCT_REVIEW_FAILED = 'UPDATE_PRODUCT_REVIEW_FAILED';
export const DELETE_PRODUCT_REQUESTED = 'DELETE_PRODUCT_REQUESTED';
export const DELETE_PRODUCT_SUCCEEDED = 'DELETE_PRODUCT_SUCCEEDED';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';
export const FETCH_ACTIVE_PRODUCTS_BY_SEARCH_REQUESTED = 'FETCH_ACTIVE_PRODUCTS_BY_SEARCH_REQUESTED';
export const FETCH_ACTIVE_PRODUCTS_BY_SEARCH_SUCCEEDED = 'FETCH_ACTIVE_PRODUCTS_BY_SEARCH_SUCCEEDED';
export const FETCH_ACTIVE_PRODUCTS_BY_SEARCH_FAILED = 'FETCH_ACTIVE_PRODUCTS_BY_SEARCH_FAILED';
export const FETCH_ACTIVE_PACKAGE_PRODUCTS_REQUESTED = 'FETCH_ACTIVE_PACKAGE_PRODUCTS_REQUESTED';
export const FETCH_ACTIVE_PACKAGE_PRODUCTS_SUCCEEDED = 'FETCH_ACTIVE_PACKAGE_PRODUCTS_SUCCEEDED';
export const FETCH_ACTIVE_PACKAGE_PRODUCTS_FAILED = 'FETCH_ACTIVE_PACKAGE_PRODUCTS_FAILED';
export const CREATE_DUPLICATE_PRODUCT_REQUEST = 'CREATE_DUPLICATE_PRODUCT_REQUEST';
export const CREATE_DUPLICATE_PRODUCT_SUCCESS = 'CREATE_DUPLICATE_PRODUCT_SUCCESS';
export const CREATE_DUPLICATE_PRODUCT_FAILED = 'CREATE_DUPLICATE_PRODUCT_FAILED';
export const FETCH_PRODUCTS_DATA_DOWNLOAD_REQUESTED = 'FETCH_PRODUCTS_DATA_DOWNLOAD_REQUESTED';
export const FETCH_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED';

//Stock Product's
export const CREATE_STOCK_PRODUCT_REQUESTED = 'CREATE_STOCK_PRODUCT_REQUESTED';
export const CREATE_STOCK_PRODUCT_SUCCEEDED = 'CREATE_STOCK_PRODUCT_SUCCEEDED';
export const CREATE_STOCK_PRODUCT_FAILED = 'CREATE_STOCK_PRODUCT_FAILED';

export const FETCH_STOCK_PRODUCTS_DATA_REQUESTED = 'FETCH_STOCK_PRODUCTS_DATA_REQUESTED';
export const FETCH_STOCK_PRODUCTS_DATA_SUCCEEDED = 'FETCH_STOCK_PRODUCTS_DATA_SUCCEEDED';
export const FETCH_STOCK_PRODUCTS_DATA_FAILED = 'FETCH_STOCK_PRODUCTS_DATA_FAILED';

export const FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_REQUESTED = 'FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_REQUESTED';
export const FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_FAILED = 'FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_FAILED';

export const FETCH_SINGLE_STOCK_PRODUCT_DATA_REQUESTED = 'FETCH_SINGLE_STOCK_PRODUCT_DATA_REQUESTED';
export const FETCH_SINGLE_STOCK_PRODUCT_DATA_SUCCEEDED = 'FETCH_SINGLE_STOCK_PRODUCT_DATA_SUCCEEDED';
export const FETCH_SINGLE_STOCK_PRODUCT_DATA_FAILED = 'FETCH_SINGLE_STOCK_PRODUCT_DATA_FAILED';

export const UPDATE_STOCK_PRODUCT_REQUESTED = 'UPDATE_STOCK_PRODUCT_REQUESTED';
export const UPDATE_STOCK_PRODUCT_SUCCEEDED = 'UPDATE_STOCK_PRODUCT_SUCCEEDED';
export const UPDATE_STOCK_PRODUCT_FAILED = 'UPDATE_STOCK_PRODUCT_FAILED';

export const FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_REQUESTED = 'FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_REQUESTED';
export const FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_SUCCEEDED = 'FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_SUCCEEDED';
export const FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_FAILED = 'FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_FAILED';

export const FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_REQUESTED = 'FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_REQUESTED';
export const FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_FAILED = 'FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_FAILED';

export const FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_REQUESTED = 'FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_REQUESTED';
export const FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_SUCCEEDED = 'FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_SUCCEEDED';
export const FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_FAILED = 'FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_FAILED';

export const CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED = 'CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED';
export const CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED = 'CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED';
export const CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED = 'CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED';

export const UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED = 'UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED';
export const UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED = 'UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED';
export const UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED = 'UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED';

export const DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED = 'DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED';
export const DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED = 'DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED';
export const DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED = 'DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED';

export const FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_REQUESTED = 'FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_REQUESTED';
export const FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_SUCCEEDED = 'FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_SUCCEEDED';
export const FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_FAILED = 'FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_FAILED';
//Coupons
export const FETCH_COUPONS_DATA_REQUESTED = 'FETCH_COUPONS_DATA_REQUESTED';
export const FETCH_COUPONS_DATA_SUCCEEDED = 'FETCH_COUPONS_DATA_SUCCEEDED';
export const FETCH_COUPONS_DATA_FAILED = 'FETCH_COUPONS_DATA_FAILED';
export const FETCH_SINGLE_COUPON_DATA_REQUESTED = 'FETCH_SINGLE_COUPON_DATA_REQUESTED';
export const FETCH_SINGLE_COUPON_DATA_SUCCEEDED = 'FETCH_SINGLE_COUPON_DATA_SUCCEEDED';
export const FETCH_SINGLE_COUPON_DATA_FAILED = 'FETCH_SINGLE_COUPON_DATA_FAILED';
export const CREATE_COUPON_REQUESTED = 'CREATE_COUPON_REQUESTED';
export const CREATE_COUPON_SUCCEEDED = 'CREATE_COUPON_SUCCEEDED';
export const CREATE_COUPON_FAILED = 'CREATE_COUPON_FAILED';
export const UPDATE_COUPON_REQUESTED = 'UPDATE_COUPON_REQUESTED';
export const UPDATE_COUPON_SUCCEEDED = 'UPDATE_COUPON_SUCCEEDED';
export const UPDATE_COUPON_FAILED = 'UPDATE_COUPON_FAILED';
export const FETCH_ACTIVE_COUPONS_BY_SEARCH_REQUESTED = 'FETCH_ACTIVE_COUPONS_BY_SEARCH_REQUESTED';
export const FETCH_ACTIVE_COUPONS_BY_SEARCH_SUCCEEDED = 'FETCH_ACTIVE_COUPONS_BY_SEARCH_SUCCEEDED';
export const FETCH_ACTIVE_COUPONS_BY_SEARCH_FAILED = 'FETCH_ACTIVE_COUPONS_BY_SEARCH_FAILED';
export const DELETE_COUPON_CATEGORY_REQUESTED = 'DELETE_COUPON_CATEGORY_REQUESTED';
export const DELETE_COUPON_CATEGORY_SUCCEEDED = 'DELETE_COUPON_CATEGORY_SUCCEEDED';
export const DELETE_COUPON_CATEGORY_FAILED = 'DELETE_COUPON_CATEGORY_FAILED';
export const DELETE_COUPON_SUB_CATEGORY_REQUESTED = 'DELETE_COUPON_SUB_CATEGORY_REQUESTED';
export const DELETE_COUPON_SUB_CATEGORY_SUCCEEDED = 'DELETE_COUPON_SUB_CATEGORY_SUCCEEDED';
export const DELETE_COUPON_SUB_CATEGORY_FAILED = 'DELETE_COUPON_SUB_CATEGORY_FAILED';
export const DELETE_COUPON_PRODUCT_REQUESTED = 'DELETE_COUPON_PRODUCT_REQUESTED';
export const DELETE_COUPON_PRODUCT_SUCCEEDED = 'DELETE_COUPON_PRODUCT_SUCCEEDED';
export const DELETE_COUPON_PRODUCT_FAILED = 'DELETE_COUPON_PRODUCT_FAILED';
export const SET_STATUS_COUPON_USER_REQUESTED = 'SET_STATUS_COUPON_USER_REQUESTED';
export const SET_STATUS_COUPON_USER_SUCCEEDED = 'SET_STATUS_COUPON_USER_SUCCEEDED';
export const SET_STATUS_COUPON_USER_FAILED = 'SET_STATUS_COUPON_USER_FAILED';


//Orders
export const FETCH_ORDERS_DATA_REQUESTED = 'FETCH_ORDERS_DATA_REQUESTED';
export const FETCH_ORDERS_DATA_SUCCEEDED = 'FETCH_ORDERS_DATA_SUCCEEDED';
export const FETCH_ORDERS_DATA_FAILED = 'FETCH_ORDERS_DATA_FAILED';
export const FETCH_ORDERS_DATA_DOWNLOAD_REQUESTED = 'FETCH_ORDERS_DATA_DOWNLOAD_REQUESTED'
export const FETCH_ORDERS_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_ORDERS_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_SINGLE_ORDER_DATA_REQUESTED = 'FETCH_SINGLE_ORDER_DATA_REQUESTED';
export const FETCH_SINGLE_ORDER_DATA_SUCCEEDED = 'FETCH_SINGLE_ORDER_DATA_SUCCEEDED';
export const FETCH_SINGLE_ORDER_DATA_FAILED = 'FETCH_SINGLE_ORDER_DATA_FAILED';
export const ORDER_STATUS_CHANGE_REQUESTED = 'ORDER_STATUS_CHANGE_REQUESTED';
export const ORDER_STATUS_CHANGE_SUCCEEDED = 'ORDER_STATUS_CHANGE_SUCCEEDED';
export const ORDER_STATUS_CHANGE_FAILED = 'ORDER_STATUS_CHANGE_FAILED';
export const ORDER_PAYMENT_STATUS_CHANGE_REQUESTED = 'ORDER_PAYMENT_STATUS_CHANGE_REQUESTED';
export const ORDER_PAYMENT_STATUS_CHANGE_SUCCEEDED = 'ORDER_PAYMENT_STATUS_CHANGE_SUCCEEDED';
export const ORDER_PAYMENT_STATUS_CHANGE_FAILED = 'ORDER_PAYMENT_STATUS_CHANGE_FAILED';
export const ORDER_PAYMENT_METHOD_CHANGE_REQUESTED = 'ORDER_PAYMENT_METHOD_CHANGE_REQUESTED';
export const ORDER_PAYMENT_METHOD_CHANGE_SUCCEEDED = 'ORDER_PAYMENT_METHOD_CHANGE_SUCCEEDED';
export const ORDER_PAYMENT_METHOD_CHANGE_FAILED = 'ORDER_PAYMENT_METHOD_CHANGE_FAILED';
export const ORDER_DELIVERED_BY_CHANGE_REQUESTED = 'ORDER_DELIVERED_BY_CHANGE_REQUESTED';
export const ORDER_DELIVERED_BY_CHANGE_SUCCEEDED = 'ORDER_DELIVERED_BY_CHANGE_SUCCEEDED';
export const ORDER_DELIVERED_BY_CHANGE_FAILED = 'ORDER_DELIVERED_BY_CHANGE_FAILED';
export const PARTIAL_UPDATE_ORDER_REQUESTED = 'PARTIAL_UPDATE_ORDER_REQUESTED';
export const PARTIAL_UPDATE_ORDER_SUCCEEDED = 'PARTIAL_UPDATE_ORDER_SUCCEEDED';
export const PARTIAL_UPDATE_ORDER_FAILED = 'PARTIAL_UPDATE_ORDER_FAILED';
export const UPDATE_ORDER_REQUESTED = 'UPDATE_ORDER_REQUESTED';
export const UPDATE_ORDER_SUCCEEDED = 'UPDATE_ORDER_SUCCEEDED';
export const UPDATE_ORDER_FAILED = 'UPDATE_ORDER_FAILED';
export const FETCH_SALES_DATA_DATA_REQUESTED = 'FETCH_SALES_DATA_DATA_REQUESTED';
export const FETCH_SALES_DATA_DATA_SUCCESS = 'FETCH_SALES_DATA_DATA_SUCCESS';
export const FETCH_SALES_DATA_DATA_FAILED = 'FETCH_SALES_DATA_DATA_FAILED';

export const DELETE_IMAGE_REQUESTED = 'DELETE_IMAGE_REQUESTED';
export const DELETE_IMAGE_SUCCEEDED = 'DELETE_IMAGE_SUCCEEDED';
export const DELETE_IMAGE_FAILED = 'DELETE_IMAGE_FAILED';

export const CHECK_EXISTING_SLUG_REQUESTED = 'CHECK_EXISTING_SLUG_REQUESTED';
export const CHECK_EXISTING_SLUG_SUCCEED = 'CHECK_EXISTING_SLUG_SUCCEED';
export const CHECK_EXISTING_SLUG_FAILED = 'CHECK_EXISTING_SLUG_FAILED';
export const REMOVE_BY_ID_OBJECT = 'REMOVE_BY_ID_OBJECT';

export const FETCH_PRODUCT_REQUESTS_DATA_REQUESTED = 'FETCH_PRODUCT_REQUESTS_DATA_REQUESTED';
export const FETCH_PRODUCT_REQUESTS_DATA_SUCCEEDED = 'FETCH_PRODUCT_REQUESTS_DATA_SUCCEEDED';
export const FETCH_PRODUCT_REQUESTS_DATA_FAILED = 'FETCH_PRODUCT_REQUESTS_DATA_FAILED';


//History
export const FETCH_PRODUCT_HISTORY_DATA_REQUESTED = 'FETCH_PRODUCT_HISTORY_DATA_REQUESTED';
export const FETCH_CATEGORY_HISTORY_DATA_REQUESTED = 'FETCH_CATEGORY_HISTORY_DATA_REQUESTED';
export const FETCH_SUB_CATEGORY_HISTORY_DATA_REQUESTED = 'FETCH_SUB_CATEGORY_HISTORY_DATA_REQUESTED';
export const FETCH_COUPON_HISTORY_DATA_REQUESTED = 'FETCH_COUPON_HISTORY_DATA_REQUESTED';
export const FETCH_ORDER_HISTORY_DATA_REQUESTED = 'FETCH_ORDER_HISTORY_DATA_REQUESTED';
export const FETCH_PRODUCT_HISTORY_DATA_SUCCEEDED = 'FETCH_PRODUCT_HISTORY_DATA_SUCCEEDED ';
export const FETCH_CATEGORY_HISTORY_DATA_SUCCEEDED = 'FETCH_CATEGORY_HISTORY_DATA_SUCCEEDED';
export const FETCH_SUB_CATEGORY_HISTORY_DATA_SUCCEEDED = 'FETCH_SUB_CATEGORY_HISTORY_DATA_SUCCEEDED';
export const FETCH_COUPON_HISTORY_DATA_SUCCEEDED = 'FETCH_COUPON_HISTORY_DATA_SUCCEEDED';
export const FETCH_ORDER_HISTORY_DATA_SUCCEEDED = 'FETCH_ORDER_HISTORY_DATA_SUCCEEDED';
export const FETCH_PRODUCT_HISTORY_DATA_FAILED = 'FETCH_PRODUCT_HISTORY_DATA_FAILED';
export const FETCH_CATEGORY_HISTORY_DATA_FAILED = 'FETCH_CATEGORY_HISTORY_DATA_FAILED';
export const FETCH_SUB_CATEGORY_HISTORY_DATA_FAILED = 'FETCH_SUB_CATEGORY_HISTORY_DATA_FAILED';
export const FETCH_COUPON_HISTORY_DATA_FAILED = 'FETCH_COUPON_HISTORY_DATA_FAILED';
export const FETCH_ORDER_HISTORY_DATA_FAILED = 'FETCH_ORDER_HISTORY_DATA_FAILED';

// Statistics
export const FETCH_DASHBOARD_DATA_REQUESTED = 'FETCH_DASHBOARD_DATA_REQUESTED';
export const FETCH_DASHBOARD_DATA_SUCCEEDED = 'FETCH_DASHBOARD_DATA_SUCCEEDED';
export const FETCH_DASHBOARD_DATA_FAILED = 'FETCH_DASHBOARD_DATA_FAILED';
export const FETCH_ADMIN_SALES_REPORT_REQUESTED = 'FETCH_ADMIN_SALES_REPORT_REQUESTED';
export const FETCH_ADMIN_SALES_REPORT_SUCCEEDED = 'FETCH_ADMIN_SALES_REPORT_SUCCEEDED';
export const FETCH_ADMIN_SALES_REPORT_FAILED = 'FETCH_ADMIN_SALES_REPORT_FAILED';

export const DOWONLOAD_INVOICE_PDF_REQUEST = 'DOWONLOAD_INVOICE_PDF_REQUEST';
export const DOWONLOAD_INVOICE_PDF_SUCCESS = 'DOWONLOAD_INVOICE_PDF_SUCCESS';
export const DOWONLOAD_INVOICE_PDF_ERROR = 'DOWONLOAD_INVOICE_PDF_ERROR';

export const PRODUCT_DATATABLE_PAGE_SIZE = 'PRODUCT_DATATABLE_PAGE_SIZE';
export const COUPON_DATATABLE_PAGE_SIZE = 'COUPON_DATATABLE_PAGE_SIZE';
export const ORDER_DATATABLE_PAGE_SIZE = 'ORDER_DATATABLE_PAGE_SIZE';
export const REQUESTED_PRODUCT_DATATABLE_PAGE_SIZE = 'REQUESTED_PRODUCT_DATATABLE_PAGE_SIZE';
export const USER_DATATABLE_PAGE_SIZE = 'USER_DATATABLE_PAGE_SIZE';

export const REMOVE_DATA_BY_TYPE_ARRAY = 'REMOVE_DATA_BY_TYPE_ARRAY';
export const REMOVE_FORM_DATA_BY_TYPE_OBJECT = 'REMOVE_FORM_DATA_BY_TYPE_OBJECT';

export const ADD_PRODUCT_VARIANT_REQUEST = 'ADD_PRODUCT_VARIANT_REQUEST';
export const ADD_PRODUCT_VARIANT_SUCCESS = 'ADD_PRODUCT_VARIANT_SUCCESS';
export const ADD_PRODUCT_VARIANT_ERROR = 'ADD_PRODUCT_VARIANT_ERROR';

export const UPDATE_PRODUCT_VARIANT_REQUEST = 'UPDATE_PRODUCT_VARIANT_REQUEST';
export const UPDATE_PRODUCT_VARIANT_SUCCESS = 'UPDATE_PRODUCT_VARIANT_SUCCESS';
export const UPDATE_PRODUCT_VARIANT_ERROR = 'UPDATE_PRODUCT_VARIANT_ERROR';

// Account
export const FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_REQUESTED = 'FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_REQUESTED';
export const FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_SUCCEEDED = 'FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_SUCCEEDED';
export const FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_FAILED = 'FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_FAILED';

export const CREATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED = 'CREATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED';
export const CREATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED = 'CREATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED';
export const CREATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED = 'CREATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED';

export const UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED = 'UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED';
export const UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED = 'UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED';
export const UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED = 'UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED';

export const FETCH_INCOME_OR_EXPENSE_DATA_REQUESTED = 'FETCH_INCOME_OR_EXPENSE_DATA_REQUESTED';
export const FETCH_INCOME_OR_EXPENSE_DATA_SUCCEEDED = 'FETCH_INCOME_OR_EXPENSE_DATA_SUCCEEDED';
export const FETCH_INCOME_OR_EXPENSE_DATA_FAILED = 'FETCH_INCOME_OR_EXPENSE_DATA_FAILED';

export const FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_REQUESTED = 'FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_REQUESTED';
export const FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_FAILED = 'FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_FAILED';

export const FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_REQUESTED = 'FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_REQUESTED';
export const FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_SUCCEEDED = 'FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_SUCCEEDED';
export const FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_FAILED = 'FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_FAILED';

export const CREATE_INCOME_OR_EXPENSE_REQUESTED = 'CREATE_INCOME_OR_EXPENSE_REQUESTED';
export const CREATE_INCOME_OR_EXPENSE_SUCCEEDED = 'CREATE_INCOME_OR_EXPENSE_SUCCEEDED';
export const CREATE_INCOME_OR_EXPENSE_FAILED = 'CREATE_INCOME_OR_EXPENSE_FAILED';

export const UPDATE_INCOME_OR_EXPENSE_REQUESTED = 'UPDATE_INCOME_OR_EXPENSE_REQUESTED';
export const UPDATE_INCOME_OR_EXPENSE_SUCCEEDED = 'UPDATE_INCOME_OR_EXPENSE_SUCCEEDED';
export const UPDATE_INCOME_OR_EXPENSE_FAILED = 'UPDATE_INCOME_OR_EXPENSE_FAILED';

export const DELETE_INCOME_OR_EXPENSE_REQUESTED = 'DELETE_INCOME_OR_EXPENSE_REQUESTED';
export const DELETE_INCOME_OR_EXPENSE_SUCCEEDED = 'DELETE_INCOME_OR_EXPENSE_SUCCEEDED';
export const DELETE_INCOME_OR_EXPENSE_FAILED = 'DELETE_INCOME_OR_EXPENSE_FAILED';

export const FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_REQUESTED = 'FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_REQUESTED'
export const FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_SUCCEEDED = 'FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_SUCCEEDED';
export const FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_FAILED = 'FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_FAILED';

export const FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_REQUESTED = 'FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_REQUESTED'
export const FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_SUCCEEDED = 'FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_SUCCEEDED';
export const FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_FAILED = 'FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_FAILED';

export const FETCH_ORDER_BY_INVOICE_ID_REQUESTED = 'FETCH_ORDER_BY_INVOICE_ID_REQUESTED';
export const FETCH_ORDER_BY_INVOICE_ID_SUCCEEDED = 'FETCH_ORDER_BY_INVOICE_ID_SUCCEEDED';
export const FETCH_ORDER_BY_INVOICE_ID_FAILED = 'FETCH_ORDER_BY_INVOICE_ID_FAILED';

export const ACCOUNT_DATATABLE_PAGE_SIZE = 'ACCOUNT_DATATABLE_PAGE_SIZE';
//Clear State
export const CLEAR_STATE_AFTER_UNMOUNTING = 'CLEAR_STATE_AFTER_UNMOUNTING';