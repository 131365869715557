import {
  REGISTRATION_REQUESTED,
  REGISTRATION_FAILED,
  REGISTRATION_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  CREATE_VENDOR_REQUESTED,
  UPDATE_VENDOR_REQUESTED,
  CREATE_VENDOR_SUCCEEDED,
  UPDATE_VENDOR_SUCCEEDED,
  CREATE_VENDOR_FAILED,
  UPDATE_VENDOR_FAILED,
} from 'constants/actions';

import * as actionType from 'constants/actions';


export const initialState = {
  login: {
    error: null,
    isRunning: false,
  },
  registration: {
    error: null,
    isRunning: false,
  },
  user: {
    error: null,
    isRunning: false,
  },
  vendor: {
    error: null,
    isRunning: false,
  },
  brand: {
    error: null,
    isRunning: false,
    success: null
  },
  category: {
    error: null,
    isRunning: false,
    success: null
  },
  subCategory: {
    error: null,
    isRunning: false,
    success: null
  },
  product: {
    error: null,
    isRunning: false,
    success: null
  },
  coupon: {
    error: null,
    isRunning: false,
    success: null
  },
  order: {
    error: null,
    isRunning: false,
  },
  image: {
    error: null,
    isRunning: false
  },
  slugCheck: {
    error: null,
    isRunning: false
  },
  package: {
    error: null,
    isRunning: false,
    success: null
  },
  productVariant: {
    error: null,
    isRunning: false,
    success: null
  },
  account: {
    error: null,
    isRunning: false,
    success: null
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        login: {
          error: action.payload,
          isRunning: false,
        }
      };
    case LOGIN_REQUESTED:
      return {
        ...state,
        login: {
          isRunning: true,
        }
      };
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          error: null,
          isRunning: false,
        }
      };
    case REGISTRATION_FAILED:
      return {
        ...state,
        registration: {
          error: action.payload,
          isRunning: false,
        }
      };
    case REGISTRATION_REQUESTED:
      return {
        ...state,
        registration: {
          isRunning: true,
        }
      };
    case REGISTRATION_SUCCEEDED:
      return {
        ...state,
        registration: {
          error: null,
          isRunning: false,
        }
      };
    case actionType.CREATE_ADMIN_DATA_REQUESTED:
    case actionType.UPDATE_ADMIN_DATA_REQUESTED:
      return {
        ...state,
        user: {
          error: null,
          isRunning: true,
        }
      };
    case actionType.CREATE_ADMIN_DATA_SUCCEEDED:
    case actionType.UPDATE_ADMIN_DATA_SUCCEEDED:
      return {
        ...state,
        user: {
          error: null,
          isRunning: false,
        }
      };
    case actionType.CREATE_ADMIN_DATA_FAILED:
    case actionType.UPDATE_ADMIN_DATA_FAILED:
      return {
        ...state,
        user: {
          isRunning: false,
          error: action.payload,
        }
      };
    case CREATE_VENDOR_REQUESTED:
    case UPDATE_VENDOR_REQUESTED:
      return {
        ...state,
        vendor: {
          error: null,
          isRunning: true,
        }
      };
    case CREATE_VENDOR_SUCCEEDED:
    case UPDATE_VENDOR_SUCCEEDED:
      return {
        ...state,
        vendor: {
          error: null,
          isRunning: false,
        }
      };
    case CREATE_VENDOR_FAILED:
    case UPDATE_VENDOR_FAILED:
      return {
        ...state,
        vendor: {
          error: action.payload,
          isRunning: false,
        }
      };
    case actionType.CREATE_BRAND_REQUESTED:
    case actionType.UPDATE_BRAND_REQUESTED:
      return {
        ...state,
        brand: {
          error: null,
          isRunning: true,
          success: null
        }
      };
    case actionType.CREATE_BRAND_SUCCEEDED:
    case actionType.UPDATE_BRAND_SUCCEEDED:
      return {
        ...state,
        brand: {
          error: null,
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.CREATE_BRAND_FAILED:
    case actionType.UPDATE_BRAND_FAILED:
      return {
        ...state,
        brand: {
          error: action.payload,
          isRunning: false,
          success: null
        }
      };
    case actionType.CREATE_CATEGORY_REQUESTED:
    case actionType.UPDATE_CATEGORY_REQUESTED:
      return {
        ...state,
        category: {
          error: null,
          isRunning: true,
          success: null
        }
      };
    case actionType.CREATE_CATEGORY_SUCCEEDED:
    case actionType.UPDATE_CATEGORY_SUCCEEDED:
      return {
        ...state,
        category: {
          error: null,
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.CREATE_CATEGORY_FAILED:
    case actionType.UPDATE_CATEGORY_FAILED:
      return {
        ...state,
        category: {
          error: action.payload,
          isRunning: false,
          success: null
        }
      };
    case actionType.CREATE_SUB_CATEGORY_REQUESTED:
    case actionType.UPDATE_SUB_CATEGORY_REQUESTED:
      return {
        ...state,
        subCategory: {
          error: null,
          isRunning: true,
          success: null
        }
      };
    case actionType.CREATE_SUB_CATEGORY_SUCCEEDED:
    case actionType.UPDATE_SUB_CATEGORY_SUCCEEDED:
      return {
        ...state,
        subCategory: {
          error: null,
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.CREATE_SUB_CATEGORY_FAILED:
    case actionType.UPDATE_SUB_CATEGORY_FAILED:
      return {
        ...state,
        subCategory: {
          error: action.payload,
          isRunning: false,
          success: null
        }
      };
    case actionType.CREATE_PRODUCT_REQUESTED:
    case actionType.CREATE_STOCK_PRODUCT_REQUESTED:
    case actionType.UPDATE_PRODUCT_REQUESTED:
    case actionType.UPDATE_PRODUCT_ANSWER_REQUESTED:
    case actionType.UPDATE_PRODUCT_REVIEW_REQUESTED:
    case actionType.UPDATE_STOCK_PRODUCT_REQUESTED:
    case actionType.CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED:
    case actionType.UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED:
    case actionType.DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED:
    case actionType.CREATE_DUPLICATE_PRODUCT_REQUEST:
      return {
        ...state,
        product: {
          error: null,
          isRunning: true,
          success: null
        }
      };
    case actionType.CREATE_PRODUCT_SUCCEEDED:
    case actionType.CREATE_STOCK_PRODUCT_SUCCEEDED:
    case actionType.UPDATE_PRODUCT_SUCCEEDED:
    case actionType.UPDATE_PRODUCT_ANSWER_SUCCEEDED:
    case actionType.UPDATE_PRODUCT_REVIEW_SUCCEEDED:
    case actionType.UPDATE_STOCK_PRODUCT_SUCCEEDED:
    case actionType.CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED:
    case actionType.UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED:
    case actionType.DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED:
    case actionType.CREATE_DUPLICATE_PRODUCT_SUCCESS:
      return {
        ...state,
        product: {
          error: null,
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.CREATE_PRODUCT_FAILED:
    case actionType.CREATE_STOCK_PRODUCT_FAILED:
    case actionType.UPDATE_PRODUCT_FAILED:
    case actionType.UPDATE_PRODUCT_ANSWER_FAILED:
    case actionType.UPDATE_PRODUCT_REVIEW_FAILED:
    case actionType.UPDATE_STOCK_PRODUCT_FAILED:
    case actionType.CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED:
    case actionType.UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED:
    case actionType.DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED:
    case actionType.CREATE_DUPLICATE_PRODUCT_FAILED:
      return {
        ...state,
        product: {
          error: action.payload,
          isRunning: false,
          success: null
        }
      };
    case actionType.CREATE_COUPON_REQUESTED:
    case actionType.UPDATE_COUPON_REQUESTED:
      return {
        ...state,
        coupon: {
          error: null,
          isRunning: true,
          success: null
        }
      };
    case actionType.CREATE_COUPON_SUCCEEDED:
    case actionType.UPDATE_COUPON_SUCCEEDED:
      return {
        ...state,
        coupon: {
          error: null,
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.CREATE_COUPON_FAILED:
    case actionType.UPDATE_COUPON_FAILED:
      return {
        ...state,
        coupon: {
          error: action.payload,
          isRunning: false,
          success: null
        }
      };
    case actionType.ORDER_STATUS_CHANGE_REQUESTED:
    case actionType.ORDER_PAYMENT_STATUS_CHANGE_REQUESTED:
    case actionType.ORDER_PAYMENT_METHOD_CHANGE_REQUESTED:
    case actionType.ORDER_DELIVERED_BY_CHANGE_REQUESTED:
    case actionType.PARTIAL_UPDATE_ORDER_REQUESTED:
      return {
        ...state,
        order: {
          error: null,
          isRunning: true,
        }
      };
    case actionType.ORDER_STATUS_CHANGE_SUCCEEDED:
    case actionType.ORDER_PAYMENT_STATUS_CHANGE_SUCCEEDED:
    case actionType.ORDER_PAYMENT_METHOD_CHANGE_SUCCEEDED:
    case actionType.ORDER_DELIVERED_BY_CHANGE_SUCCEEDED:
    case actionType.PARTIAL_UPDATE_ORDER_SUCCEEDED:
      return {
        ...state,
        order: {
          error: null,
          isRunning: false,
        }
      };
    case actionType.ORDER_STATUS_CHANGE_FAILED:
    case actionType.ORDER_PAYMENT_STATUS_CHANGE_FAILED:
    case actionType.ORDER_PAYMENT_METHOD_CHANGE_FAILED:
    case actionType.ORDER_DELIVERED_BY_CHANGE_FAILED:
    case actionType.PARTIAL_UPDATE_ORDER_FAILED:
      return {
        ...state,
        order: {
          error: action.payload,
          isRunning: false,
        }
      };
    case actionType.UPDATE_ORDER_REQUESTED:
      return {
        ...state,
        order: {
          error: null,
          isRunning: true,
        }
      };
    case actionType.UPDATE_ORDER_SUCCEEDED:
      return {
        ...state,
        order: {
          error: null,
          isRunning: false,
        }
      };
    case actionType.UPDATE_ORDER_FAILED:
      return {
        ...state,
        order: {
          error: action.payload,
          isRunning: false,
        }
      };
    case actionType.DELETE_IMAGE_REQUESTED:
      return {
        ...state,
        image: {
          error: null,
          isRunning: true,
        }
      };
    case actionType.DELETE_IMAGE_SUCCEEDED:
      return {
        ...state,
        image: {
          error: null,
          isRunning: false,
        }
      };
    case actionType.DELETE_IMAGE_FAILED:
      return {
        ...state,
        image: {
          error: action.payload,
          isRunning: false,
        }
      };
    case actionType.CHECK_EXISTING_SLUG_REQUESTED:
      return {
        ...state,
        slugCheck: {
          error: null,
          isRunning: true,
        }
      };
    case actionType.CHECK_EXISTING_SLUG_SUCCEED:
      return {
        ...state,
        slugCheck: {
          error: null,
          isRunning: false,
        }
      };
    case actionType.CHECK_EXISTING_SLUG_FAILED:
      return {
        ...state,
        slugCheck: {
          error: action.payload,
          isRunning: false,
        }
      };
    case actionType.CREATE_PACKAGE_REQUESTED:
    case actionType.UPDATE_PACKAGE_REQUESTED:
      return {
        ...state,
        package: {
          error: null,
          isRunning: true,
          success: null
        }
      };
    case actionType.CREATE_PACKAGE_SUCCEEDED:
    case actionType.UPDATE_PACKAGE_SUCCEEDED:
      return {
        ...state,
        package: {
          error: null,
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.CREATE_PACKAGE_FAILED:
    case actionType.UPDATE_PACKAGE_FAILED:
      return {
        ...state,
        package: {
          error: action.payload,
          isRunning: false,
          success: null
        }
      };
    case actionType.REMOVE_FORM_DATA_BY_TYPE_OBJECT:
      return {
        ...state,
        [action.payload.type]: {
          error: null,
          success: null,
          isRunning: false,
        }
      };
    case actionType.ADD_PRODUCT_VARIANT_REQUEST:
    case actionType.UPDATE_PRODUCT_VARIANT_REQUEST:
      return {
        ...state,
        productVariant: {
          error: null,
          isRunning: true,
          success: null
        }
      };
    case actionType.ADD_PRODUCT_VARIANT_SUCCESS:
    case actionType.UPDATE_PRODUCT_VARIANT_SUCCESS:
      return {
        ...state,
        productVariant: {
          error: null,
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.ADD_PRODUCT_VARIANT_ERROR:
    case actionType.UPDATE_PRODUCT_VARIANT_ERROR:
      return {
        ...state,
        productVariant: {
          error: action.payload,
          isRunning: false,
          success: null
        }
      };
    case actionType.CREATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED:
    case actionType.UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED:
    case actionType.CREATE_INCOME_OR_EXPENSE_REQUESTED:
    case actionType.UPDATE_INCOME_OR_EXPENSE_REQUESTED:

      return {
        ...state,
        account: {
          isRunning: true
        }
      };
    case actionType.CREATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED:
    case actionType.UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED:
    case actionType.CREATE_INCOME_OR_EXPENSE_SUCCEEDED:
    case actionType.UPDATE_INCOME_OR_EXPENSE_SUCCEEDED:
      return {
        ...state,
        account: {
          isRunning: false,
          success: action.payload
        }
      };
    case actionType.CREATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED:
    case actionType.UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED:
    case actionType.CREATE_INCOME_OR_EXPENSE_FAILED:
    case actionType.UPDATE_INCOME_OR_EXPENSE_FAILED:
      return {
        ...state,
        account: {
          isRunning: false,
          error: action.payload,
        }
      };
    default:
      return state;
  }
};
