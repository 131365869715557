const PRODUCT_WEIGHT_TYPES = [
    { name: 'Gram (gm)', value: 'gm' },
    { name: 'Kilo-gram (kg)', value: 'kg' },
    { name: 'Milliliter (ml)', value: 'ml' },
    { name: 'Liter (Ltr)', value: 'Ltr' },
    { name: 'Piece (pc)', value: 'pc' },
    { name: 'Dozen (dozen)', value: 'dozen' }
];

const STOCK_TYPE = {
    STOCK_IN: 'stock-in',
    STOCK_OUT: 'stock-out'
}

const URLPath = {
    //Route URL Path
    STOCK_PURCHASE: '/stock-products-purchase',
    STOCK_SALES: '/stock-products-sales',
    STOCK_PRODUCTS: '/stock-products',
    STOCK_SUMMARY: '/stock-products-total-sammury',

    INCOME: '/income',
    EXPENSE: '/expense',
    INCOME_SUMMARY: '/income-summary',
    EXPENSE_SUMMARY: '/expense-summary'


}

const DecimalNumber = {
    ONE: 1,
    TWO: 2
}

export { PRODUCT_WEIGHT_TYPES, STOCK_TYPE, URLPath, DecimalNumber }