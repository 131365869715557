import { FETCH_USERS_DATA_SUCCEEDED, FETCH_USERS_DATA_REQUESTED, FETCH_VENDORS_DATA_REQUESTED, FETCH_VENDORS_DATA_SUCCEEDED } from 'constants/actions';
import * as actionType from 'constants/actions';


export const initialState = {
  users: {
    error: null,
    isRunning: false,
    all: [],
    byId: {},
    userByPhoneSearch: null,
    count: 0,
    pageSize: 15,
    activeUsers: [],
  },
  vendors: {
    error: null,
    isRunning: false,
    all: [],
    byId: {},
    vendor: null
  },
  brands: {
    error: null,
    isRunning: false,
    all: [],
    byId: {},
  },
  categories: {
    error: null,
    isRunning: false,
    all: [],
    byId: {}
  },
  subCategories: {
    error: null,
    isRunning: false,
    all: [],
    byId: {}
  },
  products: {
    error: null,
    isRunning: false,
    all: [],
    byId: {},
    count: 0,
    pageSize: 15,
    activeProducts: [],
    activePackageProducts: []
  },
  coupons: {
    error: null,
    isRunning: false,
    all: [],
    byId: {},
    count: 0,
    pageSize: 15,
    activeProducts: []
  },
  orders: {
    error: null,
    isRunning: false,
    all: [],
    byId: {},
    count: 0,
    pageSize: 15,
    sales: []
  },
  productRequests: {
    error: null,
    isRunning: false,
    all: [],
    byId: {},
    count: 0,
    pageSize: 15,
  },
  statistics: {
    error: null,
    isRunning: false,
    dashboard: {},
    adminSalesReport: {},
  },
  packages: {
    error: null,
    isRunning: false,
    all: [],
    byId: {}
  },
  updateHistories: {
    error: null,
    isRunning: false,
    all: []
  },
  productsQuestionsData: {
    error: null,
    isRunning: false,
    all: [],
    byId: [],
    pageSize: 15,
  },
  productsReviewsRatingsData: {
    error: null,
    isRunning: false,
    all: [],
    byId: [],
    pageSize: 15
  },
  newsLetterEmailsData: {
    error: null,
    isRunning: false,
    all: [],
    count: 0,
    pageSize: 15,
  },
  account: {
    error: null,
    isRunning: false,
    all: [],
    total: 0,
    pageSize: 15,
    name: [],
    incomeOrExpenseById: {},  //Single Income or Expense data are stord here
    orderById: {},          //Order Data store here
    delete: {
      isRunning: false,
      success: null,
      error: null
    }
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_DATA_REQUESTED:
    case actionType.FETCH_SINGLE_USER_DATA_REQUESTED:
    case actionType.FETCH_USER_BY_PHONE_NUMBER_REQUESTED:
    case actionType.FETCH_USERS_DATA_DOWNLOAD_REQUESTED:
    case actionType.FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_REQUESTED:
      return {
        ...state,
        users: {
          ...state.users,
          isRunning: true,
        }
      };
    case FETCH_USERS_DATA_SUCCEEDED:
      return {
        ...state,
        users: {
          ...state.users,
          isRunning: false,
          all: action.payload.rows,
          total: action.payload.count,
          error: null
        }
      };
    case actionType.FETCH_SINGLE_USER_DATA_SUCCEEDED:
      return {
        ...state,
        users: {
          ...state.users,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_USER_BY_PHONE_NUMBER_SUCCEEDED:
      return {
        ...state,
        users: {
          ...state.users,
          isRunning: false,
          userByPhoneSearch: action.payload,
        }
      };
    case actionType.FETCH_USERS_DATA_DOWNLOAD_SUCCEEDED:
    case actionType.FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_SUCCEEDED:
      return {
        ...state,
        users: {
          ...state.users,
          isRunning: false,
        }
      };
    case actionType.FETCH_USERS_DATA_FAILED:
    case actionType.FETCH_USER_BY_PHONE_NUMBER_FAILED:
    case actionType.FETCH_SINGLE_USER_DATA_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          isRunning: false,
          userByPhoneSearch: null,
          byId: null,
          error: action.payload
        }
      };

    case actionType.FETCH_NEWSLETTER_EMAILS_DATA_REQUESTED:
    case actionType.FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_REQUESTED:
      return {
        ...state,
        newsLetterEmailsData: {
          ...state.newsLetterEmailsData,
          isRunning: true,
        }
      };
    case actionType.FETCH_NEWSLETTER_EMAILS_DATA_SUCCEEDED:
      return {
        ...state,
        newsLetterEmailsData: {
          ...state.newsLetterEmailsData,
          isRunning: false,
          all: action.payload.rows,
          total: action.payload.count,
          error: null
        }
      };
    case actionType.FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_SUCCEEDED:
      return {
        ...state,
        newsLetterEmailsData: {
          ...state.newsLetterEmailsData,
          isRunning: false,
        }
      };
    case actionType.FETCH_NEWSLETTER_EMAILS_DATA_FAILED:
    case actionType.FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_FAILED:
      return {
        ...state,
        newsLetterEmailsData: {
          ...state.newsLetterEmailsData,
          isRunning: false,
          userByPhoneSearch: null,
          byId: null,
          error: action.payload
        }
      };

    case FETCH_VENDORS_DATA_REQUESTED:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isRunning: true,
        }
      };
    case FETCH_VENDORS_DATA_SUCCEEDED:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isRunning: false,
          all: action.payload,
        }
      };
    case actionType.FETCH_OWN_VENDOR_REQUESTED:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isRunning: true
        }
      };
    case actionType.FETCH_OWN_VENDOR_SUCCEEDED:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isRunning: false,
          vendor: action.payload,
        }
      };
    case actionType.FETCH_OWN_VENDOR_FAILED:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isRunning: false
        }
      };
    case actionType.FETCH_BRANDS_DATA_REQUESTED:
      return {
        ...state,
        brands: {
          ...state.brands,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_BRANDS_DATA_SUCCEEDED:
      return {
        ...state,
        brands: {
          ...state.brands,
          isRunning: false,
          all: action.payload
        }
      };
    case actionType.FETCH_BRANDS_DATA_FAILED:
      return {
        ...state,
        brands: {
          ...state.brands,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_SINGLE_BRAND_DATA_REQUESTED:
      return {
        ...state,
        brands: {
          ...state.brands,
          isRunning: true,
        }
      };
    case actionType.FETCH_SINGLE_BRAND_DATA_SUCCEEDED:
      return {
        ...state,
        brands: {
          ...state.brands,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_BRAND_DATA_FAILED:
      return {
        ...state,
        brands: {
          ...state.brands,
          isRunning: false
        }
      };
    case actionType.FETCH_CATEGORIES_DATA_REQUESTED:
      return {
        ...state,
        categories: {
          ...state.categories,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_CATEGORIES_DATA_SUCCEEDED:
      return {
        ...state,
        categories: {
          ...state.categories,
          isRunning: false,
          all: action.payload,
        }
      };
    case actionType.FETCH_CATEGORIES_DATA_FAILED:
      return {
        ...state,
        categories: {
          ...state.categories,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_SINGLE_CATEGORY_DATA_REQUESTED:
      return {
        ...state,
        categories: {
          ...state.categories,
          isRunning: true,
        }
      };
    case actionType.FETCH_SINGLE_CATEGORY_DATA_SUCCEEDED:
      return {
        ...state,
        categories: {
          ...state.categories,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_CATEGORY_DATA_FAILED:
      return {
        ...state,
        categories: {
          ...state.categories,
          isRunning: false
        }
      };
    case actionType.FETCH_SUB_CATEGORIES_DATA_REQUESTED:
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_SUB_CATEGORIES_DATA_SUCCEEDED:
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          isRunning: false,
          all: action.payload,
        }
      };
    case actionType.FETCH_SUB_CATEGORIES_DATA_FAILED:
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_SINGLE_SUB_CATEGORY_DATA_REQUESTED:
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          isRunning: true,
        }
      };
    case actionType.FETCH_SINGLE_SUB_CATEGORY_DATA_SUCCEEDED:
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_SUB_CATEGORY_DATA_FAILED:
      return {
        ...state,
        subCategories: {
          ...state.subCategories,
          isRunning: false
        }
      };
    case actionType.FETCH_PRODUCTS_DATA_REQUESTED:
    case actionType.FETCH_STOCK_PRODUCTS_DATA_REQUESTED:
    case actionType.FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_REQUESTED:
    case actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_REQUESTED:
    case actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_REQUESTED:
    case actionType.FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_REQUESTED:
    case actionType.FETCH_ACTIVE_PRODUCTS_BY_SEARCH_REQUESTED:
    case actionType.FETCH_ACTIVE_PACKAGE_PRODUCTS_REQUESTED:
    case actionType.FETCH_PRODUCTS_DATA_DOWNLOAD_REQUESTED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_DATA_SUCCEEDED:
    case actionType.FETCH_STOCK_PRODUCTS_DATA_SUCCEEDED:
    case actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_SUCCEEDED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          all: action.payload.rows,
          total: action.payload.count
        }
      };
    case actionType.FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_SUCCEEDED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          all: action.payload
        }
      };
    case actionType.FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED:
    case actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_SUCCEEDED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED:
    case actionType.FETCH_PRODUCTS_DATA_FAILED:
    case actionType.FETCH_STOCK_PRODUCTS_DATA_FAILED:
    case actionType.FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_FAILED:
    case actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_FAILED:
    case actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_FAILED:
    case actionType.FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_FAILED:
    case actionType.FETCH_ACTIVE_PRODUCTS_BY_SEARCH_FAILED:
    case actionType.FETCH_ACTIVE_PACKAGE_PRODUCTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_ACTIVE_PRODUCTS_BY_SEARCH_SUCCEEDED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          activeProducts: action.payload,
          activePackageProducts: []
        }
      };
    case actionType.FETCH_ACTIVE_PACKAGE_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          activePackageProducts: action.payload,
          activeProducts: []
        }
      };
    case actionType.FETCH_SINGLE_PRODUCT_DATA_REQUESTED:
    case actionType.FETCH_SINGLE_STOCK_PRODUCT_DATA_REQUESTED:
    case actionType.FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_REQUESTED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: true
        }
      };
    case actionType.FETCH_SINGLE_PRODUCT_DATA_SUCCEEDED:
    case actionType.FETCH_SINGLE_STOCK_PRODUCT_DATA_SUCCEEDED:
    case actionType.FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_SUCCEEDED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_PRODUCT_DATA_FAILED:
    case actionType.FETCH_SINGLE_STOCK_PRODUCT_DATA_FAILED:
    case actionType.FETCH_PRODUCT_HISTORY_DATA_FAILED:
    case actionType.FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.DELETE_PRODUCT_REQUESTED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: true
        }
      };
    case actionType.DELETE_PRODUCT_SUCCEEDED:
    case actionType.DELETE_PRODUCT_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          isRunning: false
        }
      };
    case actionType.FETCH_COUPONS_DATA_REQUESTED:
    case actionType.FETCH_ACTIVE_COUPONS_BY_SEARCH_REQUESTED:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_COUPONS_DATA_SUCCEEDED:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          isRunning: false,
          byId: {},
          all: action.payload.rows,
          total: action.payload.count
        }
      };
    case actionType.FETCH_COUPONS_DATA_FAILED:
    case actionType.FETCH_ACTIVE_COUPONS_BY_SEARCH_FAILED:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_ACTIVE_COUPONS_BY_SEARCH_SUCCEEDED:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          isRunning: false,
          activeCoupons: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_COUPON_DATA_REQUESTED:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          isRunning: true,
          byId: {}
        }
      };
    case actionType.FETCH_SINGLE_COUPON_DATA_SUCCEEDED:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_COUPON_DATA_FAILED:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          isRunning: false
        }
      };
    case actionType.FETCH_ORDERS_DATA_REQUESTED:
    case actionType.FETCH_ORDERS_DATA_DOWNLOAD_REQUESTED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: true,
          error: null
        }
      };

    case actionType.FETCH_ORDERS_DATA_SUCCEEDED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: false,
          all: action.payload.rows,
          total: action.payload.count
        }
      };
    case actionType.FETCH_ORDERS_DATA_DOWNLOAD_SUCCEEDED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: false
        }
      };
    case actionType.FETCH_ORDERS_DATA_FAILED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_SALES_DATA_DATA_REQUESTED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_SALES_DATA_DATA_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: false,
          sales: action.payload.rows
        }
      };
    case actionType.FETCH_SALES_DATA_DATA_FAILED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_SINGLE_ORDER_DATA_REQUESTED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: true
        }
      };
    case actionType.FETCH_SINGLE_ORDER_DATA_SUCCEEDED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_ORDER_DATA_FAILED:
      return {
        ...state,
        orders: {
          ...state.orders,
          isRunning: false
        }
      };
    case actionType.REMOVE_BY_ID_OBJECT:
      state[action.payload.type].byId = {};
      return { ...state };

    case actionType.CLEAR_STATE_AFTER_UNMOUNTING:
      for (const { stateName, properties } of action.payload) {
        for (const property of properties) {
          state[stateName][property] = initialState[stateName][property];
        }
      }

      return { ...state };

    case actionType.FETCH_PRODUCT_REQUESTS_DATA_REQUESTED:
      return {
        ...state,
        productRequests: {
          ...state.productRequests,
          isRunning: true
        }
      };
    case actionType.FETCH_PRODUCT_REQUESTS_DATA_SUCCEEDED:
      return {
        ...state,
        productRequests: {
          ...state.productRequests,
          isRunning: false,
          all: action.payload,
        }
      };
    case actionType.FETCH_PRODUCT_REQUESTS_DATA_FAILED:
      return {
        ...state,
        productRequests: {
          ...state.productRequests,
          isRunning: false
        }
      };

    case actionType.FETCH_DASHBOARD_DATA_REQUESTED:
    case actionType.FETCH_ADMIN_SALES_REPORT_REQUESTED:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_DASHBOARD_DATA_SUCCEEDED:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isRunning: false,
          dashboard: action.payload,
        }
      };
    case actionType.FETCH_ADMIN_SALES_REPORT_SUCCEEDED:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isRunning: false,
          adminSalesReport: action.payload,
        }
      };
    case actionType.FETCH_DASHBOARD_DATA_FAILED:
    case actionType.FETCH_ADMIN_SALES_REPORT_FAILED:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.PRODUCT_DATATABLE_PAGE_SIZE:
      return {
        ...state,
        products: {
          ...state.products,
          pageSize: action.payload.size
        }
      };
    case actionType.ORDER_DATATABLE_PAGE_SIZE:
      return {
        ...state,
        orders: {
          ...state.orders,
          pageSize: action.payload.size
        }
      };
    case actionType.USER_DATATABLE_PAGE_SIZE:
      return {
        ...state,
        users: {
          ...state.users,
          pageSize: action.payload.size,
        }
      };
    case actionType.FETCH_PACKAGES_DATA_REQUESTED:
      return {
        ...state,
        packages: {
          ...state.packages,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_PACKAGES_DATA_SUCCEEDED:
      return {
        ...state,
        packages: {
          ...state.packages,
          isRunning: false,
          all: action.payload,
        }
      };
    case actionType.FETCH_PACKAGES_DATA_FAILED:
      return {
        ...state,
        packages: {
          ...state.packages,
          isRunning: false,
          error: action.payload
        }
      };
    case actionType.FETCH_SINGLE_PACKAGE_DATA_REQUESTED:
      return {
        ...state,
        packages: {
          ...state.packages,
          isRunning: true,
        }
      };
    case actionType.FETCH_SINGLE_PACKAGE_DATA_SUCCEEDED:
      return {
        ...state,
        packages: {
          ...state.packages,
          isRunning: false,
          byId: action.payload,
        }
      };
    case actionType.FETCH_SINGLE_PACKAGE_DATA_FAILED:
      return {
        ...state,
        packages: {
          ...state.packages,
          isRunning: false
        }
      };
    case actionType.FETCH_ORDER_HISTORY_DATA_REQUESTED:
    case actionType.FETCH_COUPON_HISTORY_DATA_REQUESTED:
    case actionType.FETCH_CATEGORY_HISTORY_DATA_REQUESTED:
    case actionType.FETCH_SUB_CATEGORY_HISTORY_DATA_REQUESTED:
    case actionType.FETCH_PRODUCT_HISTORY_DATA_REQUESTED:
      return {
        ...state,
        updateHistories: {
          isRunning: true,
          all: [],
          error: null
        }
      };
    case actionType.FETCH_ORDER_HISTORY_DATA_SUCCEEDED:
    case actionType.FETCH_COUPON_HISTORY_DATA_SUCCEEDED:
    case actionType.FETCH_CATEGORY_HISTORY_DATA_SUCCEEDED:
    case actionType.FETCH_SUB_CATEGORY_HISTORY_DATA_SUCCEEDED:
    case actionType.FETCH_PRODUCT_HISTORY_DATA_SUCCEEDED:
      return {
        ...state,
        updateHistories: {
          isRunning: false,
          all: action.payload,
          error: null
        }
      };
    case actionType.FETCH_ORDER_HISTORY_DATA_FAILED:
    case actionType.FETCH_COUPON_HISTORY_DATA_FAILED:
    case actionType.FETCH_CATEGORY_HISTORY_DATA_FAILED:
    case actionType.FETCH_SUB_CATEGORY_HISTORY_DATA_FAILED:
    case actionType.FETCH_PRODUCT_HISTORY_DATA_FAILED:
      return {
        ...state,
        updateHistories: {
          isRunning: false,
          all: [],
          error: action.payload
        }
      };
    case actionType.FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_REQUESTED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_SUCCEEDED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: false,
          byId: action.payload,
          error: null
        }
      };
    case actionType.FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_FAILED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: false,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_QUESTIONS_DATA_REQUESTED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_QUESTIONS_DATA_SUCCEEDED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: false,
          all: action.payload.rows,
          total: action.payload.count,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_QUESTIONS_DATA_FAILED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: false,
          error: null
        }
      };
    case actionType.FETCH_PRODUCT_QUESTION_COUNT_REQUESTED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_PRODUCT_QUESTION_COUNT_SUCCEEDED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: false,
          count: action.payload,
          error: null
        }
      };
    case actionType.FETCH_PRODUCT_QUESTION_COUNT_FAILED:
      return {
        ...state,
        productsQuestionsData: {
          ...state.productsQuestionsData,
          isRunning: false,
          error: null
        }
      };
    case actionType.FETCH_PRODUCT_REVIEWS_DATA_REQUESTED:
      return {
        ...state,
        productsReviewsRatingsData: {
          ...state.productsReviewsRatingsData,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_PRODUCT_REVIEWS_DATA_SUCCEEDED:
      return {
        ...state,
        productsReviewsRatingsData: {
          ...state.productsReviewsRatingsData,
          isRunning: false,
          byId: action.payload,
          error: null
        }
      };
    case actionType.FETCH_PRODUCT_REVIEWS_DATA_FAILED:
      return {
        ...state,
        productsReviewsRatingsData: {
          ...state.productsReviewsRatingsData,
          isRunning: false,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_REQUESTED:
      return {
        ...state,
        productsReviewsRatingsData: {
          ...state.productsReviewsRatingsData,
          isRunning: true,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_SUCCEEDED:
      return {
        ...state,
        productsReviewsRatingsData: {
          ...state.productsReviewsRatingsData,
          isRunning: false,
          all: action.payload.rows,
          total: action.payload.count,
          error: null
        }
      };
    case actionType.FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_FAILED:
      return {
        ...state,
        productsReviewsRatingsData: {
          ...state.productsReviewsRatingsData,
          isRunning: false,
          error: null
        }
      };

    //Account
    case actionType.FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_REQUESTED:

    case actionType.FETCH_INCOME_OR_EXPENSE_DATA_REQUESTED:
    case actionType.FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_REQUESTED:
    case actionType.FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_REQUESTED:

    case actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_REQUESTED:
    case actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_REQUESTED:

    case actionType.FETCH_ORDER_BY_INVOICE_ID_REQUESTED:
      return {
        ...state,
        account: {
          ...state.account,
          isRunning: true,
          error: null
        }
      };

    case actionType.DELETE_INCOME_OR_EXPENSE_REQUESTED:
      return {
        ...state,
        account: {
          ...state.account,
          delete: {
            ...state.account.delete,
            isRunning: true,
            success: null,
            error: null
          }
        }
      };

    case actionType.FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_SUCCEEDED:
      return {
        ...state,
        account: {
          ...state.account,
          name: action.payload,
          isRunning: false,

        }
      };

    case actionType.FETCH_INCOME_OR_EXPENSE_DATA_SUCCEEDED:
      const { rows, count } = action.payload;
      return {
        ...state,
        account: {
          ...state.account,
          all: rows,
          total: count,
          isRunning: false,

        }
      };

    case actionType.FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_SUCCEEDED:
    case actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_SUCCEEDED:
      return {
        ...state,
        account: {
          ...state.account,
          isRunning: false
        }
      };

    case actionType.FETCH_ORDER_BY_INVOICE_ID_SUCCEEDED:
      return {
        ...state,
        account: {
          ...state.account,
          orderById: action.payload,
          isRunning: false,

        }
      };

    case actionType.FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_SUCCEEDED:
      return {
        ...state,
        account: {
          ...state.account,
          incomeOrExpenseById: action.payload,
          isRunning: false,

        }
      };

    case actionType.DELETE_INCOME_OR_EXPENSE_SUCCEEDED:
      return {
        ...state,
        account: {
          ...state.account,
          delete: {
            ...state.account.delete,
            isRunning: false,
            success: "Delete Succeeded!"
          }
        }
      };

    case actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_SUCCEEDED:
      return {
        ...state,
        account: {
          ...state.account,
          all: action.payload,
          isRunning: false,

        }
      };

    case actionType.FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_FAILED:

    case actionType.FETCH_INCOME_OR_EXPENSE_DATA_FAILED:
    case actionType.FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_FAILED:
    case actionType.FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_FAILED:

    case actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_FAILED:
    case actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_FAILED:

    case actionType.FETCH_ORDER_BY_INVOICE_ID_FAILED:
      return {
        ...state,
        account: {
          ...state.account,
          isRunning: false,
          error: action.payload
        }
      };

    case actionType.DELETE_INCOME_OR_EXPENSE_FAILED:
      return {
        ...state,
        account: {
          ...state.account,
          delete: {
            ...state.account.delete,
            isRunning: false,
            error: "Delete Failed!"
          }
        }
      };

    case actionType.ACCOUNT_DATATABLE_PAGE_SIZE:
      return {
        ...state,
        account: {
          ...state.account,
          pageSize: action.payload.size
        }
      };


    //MISC
    case actionType.REMOVE_DATA_BY_TYPE_ARRAY:
      if (action.payload.type === 'statistics') {
        state[action.payload.type].dashboard = {};
      } else {
        state[action.payload.type].all = [];
      }
      return { ...state };
    default:
      return state;
  }
};