import {
  LOGIN_SUCCEEDED,
  LOGOUT_REQUESTED,
} from 'constants/actions';


export const initialState = {
  token: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        token: action.payload.token,
      };
    case LOGOUT_REQUESTED:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};
