import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError, switchMap } from 'rxjs/operators';
import {
  registrationFailed,
  registrationSucceeded,
  registrationRequested,
  loginSucceeded,
  loginFailed,
  createVendorSucceeded,
  createVendorFailed,
  updateVendorSucceeded,
  updateVendorFailed,
} from 'actions/forms';
import * as formActions from 'actions/forms';
import { REGISTRATION_REQUESTED, LOGIN_REQUESTED, CREATE_VENDOR_REQUESTED } from 'constants/actions';
import * as actionType from 'constants/actions';
import { API_ENDPOINT } from 'constants/api';
import { getSerialize } from 'utils/helpers';

//------------------------------------------Users-----------------------------------------
export const loginEpic = (action$) => action$.pipe(
  ofType(LOGIN_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ajax.post(`${API_ENDPOINT}/users/admin/token/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return loginSucceeded(response.response);
        }
        return loginFailed('Invalid response from server.');
      }),
      catchError((error) => of(loginFailed(error.response || { msg: 'Server not reachable!' }))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const registrationEpic = (action$) => action$.pipe(
  ofType(REGISTRATION_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ajax.post(`${API_ENDPOINT}/users/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return registrationSucceeded(response.response.data);
        }
        return registrationFailed('Invalid response from server.');
      }),
      catchError((error) => of(registrationFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const createAdminEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_ADMIN_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/users/admin/create/`, action.payload, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createAdminDataSucceeded(response.response);
        }
        return formActions.createAdminDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createAdminDataFailed(error.response))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateAdminEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_ADMIN_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    let formData = Object.assign({}, action.payload);
    delete formData['id'];
    return ajax.patch(`${API_ENDPOINT}/users/admin/update/${action.payload.id}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateAdminDataSucceeded(response.response);
        }
        return formActions.updateAdminDataFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateAdminDataFailed(error.response))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Vendors-----------------------------------------
export const createVendorEpic = (action$, store$) => action$.pipe(
  ofType(CREATE_VENDOR_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/vendors/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return createVendorSucceeded(response.response.data);
        }
        return createVendorFailed('Invalid response from server.');
      }),
      catchError((error) => of(createVendorFailed(error))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateVendorEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_VENDOR_REQUESTED),
  switchMap((action) => {
    const { id: vendorId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/vendors/${vendorId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return updateVendorSucceeded(response.response.data);
        }
        return updateVendorFailed('Invalid response from server.');
      }),
      catchError((error) => of(updateVendorFailed(error.response))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Brands-----------------------------------------
export const createBrandEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_BRAND_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/brands/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createBrandSucceeded(response.response.message);
        }
        return formActions.createBrandFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createBrandFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateBrandEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_BRAND_REQUESTED),
  switchMap((action) => {
    const { id: brandId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/brands/${brandId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateBrandSucceeded(response.response.message);
        }
        return formActions.updateBrandFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateBrandFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Categories-----------------------------------------
export const createCategoryEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_CATEGORY_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/categories/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createCategorySucceeded(response.response.message);
        }
        return formActions.createCategoryFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createCategoryFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateCategoryEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_CATEGORY_REQUESTED),
  switchMap((action) => {
    const { id: categoryId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/categories/${categoryId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateCategorySucceeded(response.response.message);
        }
        return formActions.updateCategoryFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateCategoryFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const deleteCategoryBrandEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_CATEGORY_BRAND_REQUESTED),
  switchMap((action) => {
    let { id } = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/category-brand/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.deleteCategoryBrandSucceeded(response.response.data);
        }
        return formActions.deleteCategoryBrandFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.deleteCategoryBrandFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Sub Categories-----------------------------------------
export const createSubCategoryEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_SUB_CATEGORY_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/sub_categories/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createSubCategorySucceeded(response.response.message);
        }
        return formActions.createSubCategoryFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createSubCategoryFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateSubCategoryEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_SUB_CATEGORY_REQUESTED),
  switchMap((action) => {
    const { id: subCategoryId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/sub_categories/${subCategoryId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateSubCategorySucceeded(response.response.message);
        }
        return formActions.updateSubCategoryFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateSubCategoryFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Products-----------------------------------------
export const createProductEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_PRODUCT_REQUESTED),
  switchMap((action) => {
    let formData = action.payload;
    if (!formData.brandId) {
      delete formData.brandId;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/products/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createProductSucceeded(response.response.message);
        }
        return formActions.createProductFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createProductFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateProductEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_PRODUCT_REQUESTED),
  switchMap((action) => {
    let { id: productId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/products/${productId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateProductSucceeded(response.response.message);
        }
        return formActions.updateProductFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateProductFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const createDuplicateProductEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_DUPLICATE_PRODUCT_REQUEST),
  switchMap((action) => {
    let formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/products/create-duplicate-product/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createDuplicateProductSuccess(response.response.message);
        }
        return formActions.createDuplicateProductFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createDuplicateProductFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const createProductVariantEpic = (action$, store$) => action$.pipe(
  ofType(actionType.ADD_PRODUCT_VARIANT_REQUEST),
  switchMap((action) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/product-variants/`, action.payload, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.addProductVariantSuccess(response.response.message);
        }
        return formActions.addProductVariantFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.addProductVariantFailed(error.response ? error.response.message : 'error'))),
    )
  }),
);

export const updateProductVariantEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_PRODUCT_VARIANT_REQUEST),
  switchMap((action) => {
    const { id, ...payload } = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/product-variants/${id}/`, payload, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateProductVariantSuccess(response.response.message);
        }
        return formActions.updateProductVariantFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateProductVariantFailed(error.response ? error.response.message : 'error'))),
    )
  }),
);

//------------------------------------------Products Q&A, Reviews-----------------------------------------
export const updateProductAnswerEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_PRODUCT_ANSWER_REQUESTED),
  switchMap((action) => {
    let { id: id, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/product-answer/${id}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateProductAnswerSucceeded(response.response.message);
        }
        return formActions.updateProductAnswerFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateProductAnswerFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateProductReviewRatingEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_PRODUCT_REVIEW_REQUESTED),
  switchMap((action) => {
    let { id: id, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/product-review-rating/${id}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateProductReviewRatingSucceeded(response.response.message);
        }
        return formActions.updateProductReviewRatingFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateProductReviewRatingFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Stock Products-----------------------------------------
export const createStockProductEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_STOCK_PRODUCT_REQUESTED),
  switchMap((action) => {
    let formData = action.payload;
    if (!formData.brandId) {
      delete formData.brandId;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/stock-products/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createStockProductSucceeded(response.response.message);
        }
        return formActions.createStockProductFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createStockProductFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const updateStockProductEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_STOCK_PRODUCT_REQUESTED),
  switchMap((action) => {
    let { id: productId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/stock-products/${productId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateStockProductSucceeded(response.response.message);
        }
        return formActions.updateStockProductFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateStockProductFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const createStockProductPurchaseOrSalesEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/stock-products/purchase-or-sales/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createStockProductPurchaseOrSalesSucceeded(response.response.message);
        }
        return formActions.createStockProductPurchaseOrSalesFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createStockProductPurchaseOrSalesFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const updateStockProductPurchaseOrSalesEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED),
  switchMap((action) => {
    let { id, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/stock-products/purchase-or-sales/${id}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateStockProductPurchaseOrSalesSucceeded(response.response.message);
        }
        return formActions.updateStockProductPurchaseOrSalesFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateStockProductPurchaseOrSalesFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const deleteStockProductPurchaseOrSalesEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED),
  switchMap((action) => {
    const headers = {
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/stock-products/purchase-or-sales/?${getSerialize(action.payload)}`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.deleteStockProductPurchaseOrSalesSucceeded(response.response.message);
        }
        return formActions.deleteStockProductPurchaseOrSalesFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.deleteStockProductPurchaseOrSalesFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

//------------------------------------------Coupons-----------------------------------------
export const createCouponEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_COUPON_REQUESTED),
  switchMap((action) => {
    let formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/coupons/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 201) {
          return formActions.createCouponSucceeded(response.response.message);
        }
        return formActions.createCouponFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createCouponFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);


export const updateCouponEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_COUPON_REQUESTED),
  switchMap((action) => {
    let { id: couponId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/coupons/${couponId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateCouponSucceeded(response.response.message);
        }
        return formActions.updateCouponFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateCouponFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const deleteCouponCategoryEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_COUPON_CATEGORY_REQUESTED),
  switchMap((action) => {
    let { id } = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/coupons-category/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.deleteCouponCategorySucceeded(response.response.data);
        }
        return formActions.deleteCouponCategoryFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.deleteCouponCategoryFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const deleteCouponSubCategoryEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_COUPON_SUB_CATEGORY_REQUESTED),
  switchMap((action) => {
    let { id } = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/coupons-subcategory/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.deleteCouponSubCategorySucceeded(response.response.data);
        }
        return formActions.deleteCouponSubCategoryFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.deleteCouponSubCategoryFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const deleteCouponProductEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_COUPON_PRODUCT_REQUESTED),
  switchMap((action) => {
    let { id } = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/coupons-product/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.deleteCouponProductSucceeded(response.response.message);
        }
        return formActions.deleteCouponProductFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.deleteCouponProductFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const setStatusCouponUserEpic = (action$, store$) => action$.pipe(
  ofType(actionType.SET_STATUS_COUPON_USER_REQUESTED),
  switchMap((action) => {
    let { id } = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.delete(`${API_ENDPOINT}/coupons-user/${id}/`, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.setStatusCouponUserSucceeded(response.response.message);
        }
        return formActions.setStatusCouponUserFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.setStatusCouponUserFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Orders-----------------------------------------
export const orderStatusChangeEpic = (action$, store$) => action$.pipe(
  ofType(actionType.ORDER_STATUS_CHANGE_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/orders/status-change/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.orderStatusChangeSucceeded(response.response.data);
        }
        return formActions.orderStatusChangeFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.orderStatusChangeFailed(error.response.msg || error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const orderPaymentStatusChangeEpic = (action$, store$) => action$.pipe(
  ofType(actionType.ORDER_PAYMENT_STATUS_CHANGE_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/orders/payment-status-change/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.orderPaymentStatusChangeSucceeded(response.response.data);
        }
        return formActions.orderPaymentStatusChangeFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.orderPaymentStatusChangeFailed(error.response.msg || error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const orderPaymentMethodChangeEpic = (action$, store$) => action$.pipe(
  ofType(actionType.ORDER_PAYMENT_METHOD_CHANGE_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/orders/payment-method-change/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.orderPaymentMethodChangeSucceeded(response.response.data);
        }
        return formActions.orderPaymentMethodChangeFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.orderPaymentMethodChangeFailed(error.response.msg || error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const orderDeliveredByChangeEpic = (action$, store$) => action$.pipe(
  ofType(actionType.ORDER_DELIVERED_BY_CHANGE_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/orders/delivered-by-change/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.orderDeliveredByChangeSucceeded(response.response.data);
        }
        return formActions.orderDeliveredByChangeFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.orderDeliveredByChangeFailed(error.response.msg || error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const partialUpdateOrderEpic = (action$, store$) => action$.pipe(
  ofType(actionType.PARTIAL_UPDATE_ORDER_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/orders/partial-update/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.partialUpdateOrderSucceeded(response.response.data);
        }
        return formActions.partialUpdateOrderFailed('Failed to update');
      }),
      catchError((error) => of(formActions.partialUpdateOrderFailed(error.response.msg || error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updateOrderEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_ORDER_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/orders/add-update/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200 && response.response.success) {
          return formActions.updateOrderSucceeded(response.response);
        } else {
          return formActions.updateOrderFailed(response.response.errors[0].msg);
        }
      }),
      catchError((error) => of(formActions.updateOrderFailed(error.response.msg || error.response.message))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Packages-----------------------------------------
export const createPackageEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_PACKAGE_REQUESTED),
  switchMap((action) => {
    const formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/packages/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createPackageSucceed(response.response.message);
        }
        return formActions.createPackageFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createPackageFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

export const updatePackageEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_PACKAGE_REQUESTED),
  switchMap((action) => {
    const { id: packageId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/packages/${packageId}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updatePackageSucceed(response.response.message);
        }
        return formActions.updatePackageFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updatePackageFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Misc-----------------------------------------
export const deleteImageEpic = (action$, store$) => action$.pipe(
  ofType(actionType.DELETE_IMAGE_REQUESTED),
  switchMap((action) => {
    let imagePath = action.payload

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/delete/image/`, imagePath, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.deleteImageSucceed(response.response.data);
        }
        return formActions.deleteImageFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.deleteImageFailed(error.response ? error.response.message : 'error'))),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);


export const checkCatSlugEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CHECK_EXISTING_SLUG_REQUESTED),
  switchMap((action) => {
    const payload = action.payload
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/check-cat-sub-cat-slug/`, payload, headers).pipe(
      map((response) => {
        const data = response.response;
        if (data.slugExists) {
          return formActions.checkCatSlugFailed(`URL name(slug) already exists in ${data.slugAt}`);
        } else {
          return formActions.checkCatSlugSucceed(data);
        }
      }),
      catchError((error) => {
        console.log(error)
        return of(formActions.checkCatSlugFailed(error.response ? error.response.message : 'error'));
      }),
      // takeUntil(action$.pipe(
      //   ofType(actionTypes.SIGN_OUT_REQUEST)
      // ))
    )
  }),
);

//------------------------------------------Account-----------------------------------------
export const createIncomeORExpenseTypeNameEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED),
  switchMap((action) => {
    let formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/income-or-expense-type-name/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createIncomeORExpenseTypeNameSucceeded(response.response.message);
        }
        return formActions.createIncomeORExpenseTypeNameFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createIncomeORExpenseTypeNameFailed(error.response ? (error.response.message || error.response[0].message) : 'error')))
    )
  }),
);

export const updateIncomeORExpenseTypeNameEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED),
  switchMap((action) => {
    const { id: incomeOrExpenseTypeNameId, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/income-or-expense-type-name/${incomeOrExpenseTypeNameId}`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateIncomeORExpenseTypeNameSucceeded(response.response.message);
        }
        return formActions.updateIncomeORExpenseTypeNameFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateIncomeORExpenseTypeNameFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const createIncomeORExpenseEpic = (action$, store$) => action$.pipe(
  ofType(actionType.CREATE_INCOME_OR_EXPENSE_REQUESTED),
  switchMap((action) => {
    let formData = action.payload;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.post(`${API_ENDPOINT}/income-expense/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.createIncomeORExpenseSucceeded(response.response.message);
        }
        return formActions.createIncomeORExpenseFailed('Invalid response from server.');
      }),
      catchError((error) => of(formActions.createIncomeORExpenseFailed(error.response ? error.response.message : 'error')))
    )
  }),
);

export const updateIncomeORExpenseEpic = (action$, store$) => action$.pipe(
  ofType(actionType.UPDATE_INCOME_OR_EXPENSE_REQUESTED),
  switchMap((action) => {
    let { id, ...rest } = action.payload;
    const formData = rest;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store$.value.auth.token}`,
    };
    return ajax.patch(`${API_ENDPOINT}/income-expense/${id}/`, formData, headers).pipe(
      map((response) => {
        const { status } = response;
        if (status === 200) {
          return formActions.updateIncomeORExpenseSucceeded(response.response.message);
        }
        return formActions.updateIncomeORExpenseSucceeded('Invalid response from server.');
      }),
      catchError((error) => of(formActions.updateIncomeORExpenseFailed(error.response ? error.response.message : 'error')))
    )
  }),
);
