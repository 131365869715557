import { FETCH_USERS_DATA_FAILED, FETCH_USERS_DATA_REQUESTED, FETCH_USERS_DATA_SUCCEEDED, FETCH_VENDORS_DATA_FAILED, FETCH_VENDORS_DATA_REQUESTED, FETCH_VENDORS_DATA_SUCCEEDED, FETCH_PRODUCTS_DATA_REQUESTED, FETCH_COUPONS_DATA_REQUESTED } from 'constants/actions';
import * as actionType from 'constants/actions';

//Users
export const fetchUsersDataDownloadRequested = (payload) => {
  return { type: actionType.FETCH_USERS_DATA_DOWNLOAD_REQUESTED, payload };
}

export const fetchUsersDataDownloadSucceeded = (payload) => {
  return { type: actionType.FETCH_USERS_DATA_DOWNLOAD_SUCCEEDED, payload };
}

export const fetchProductWiseUsersDataDownloadRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_REQUESTED, payload };
}

export const fetchProductWiseUsersDataDownloadSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCT_WISE_USERS_DATA_DOWNLOAD_SUCCEEDED, payload };
}

export const fetchUsersDataFailed = (payload) => {
  return { type: FETCH_USERS_DATA_FAILED, payload };
}
export const fetchUsersDataRequested = (payload) => {
  return { type: FETCH_USERS_DATA_REQUESTED, payload };
}
export const fetchUsersDataSucceeded = (payload) => {
  return { type: FETCH_USERS_DATA_SUCCEEDED, payload };
}

export const fetchSingleUserDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_USER_DATA_REQUESTED, payload };
}
export const fetchSingleUserDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_USER_DATA_SUCCEEDED, payload };
}
export const fetchSingleUserDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_USER_DATA_FAILED, payload };
}

export const fetchUserByPhoneNumberRequested = (payload) => {
  return { type: actionType.FETCH_USER_BY_PHONE_NUMBER_REQUESTED, payload };
}
export const fetchUserByPhoneNumberSucceeded = (payload) => {
  return { type: actionType.FETCH_USER_BY_PHONE_NUMBER_SUCCEEDED, payload };
}
export const fetchUserByPhoneNumberFailed = (payload) => {
  return { type: actionType.FETCH_USER_BY_PHONE_NUMBER_FAILED, payload };
}

//Vendors
export const fetchVendorsDataFailed = (payload) => {
  return { type: FETCH_VENDORS_DATA_FAILED, payload };
}

export const fetchVendorsDataRequested = (payload) => {
  return { type: FETCH_VENDORS_DATA_REQUESTED, payload };
}

export const fetchVendorsDataSucceeded = (payload) => {
  return { type: FETCH_VENDORS_DATA_SUCCEEDED, payload };
}

export const fetchOwnVendorRequested = (payload) => {
  return { type: actionType.FETCH_OWN_VENDOR_REQUESTED, payload };
}

export const fetchOwnVendorSucceeded = (payload) => {
  return { type: actionType.FETCH_OWN_VENDOR_SUCCEEDED, payload };
}

export const fetchOwnVendorFailed = (payload) => {
  return { type: actionType.FETCH_OWN_VENDOR_FAILED, payload };
}

//Brands
export const fetchBrandsDataRequested = (payload) => {
  return { type: actionType.FETCH_BRANDS_DATA_REQUESTED, payload };
}

export const fetchBrandsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_BRANDS_DATA_SUCCEEDED, payload };
}

export const fetchBrandsDataFailed = (payload) => {
  return { type: actionType.FETCH_BRANDS_DATA_FAILED, payload };
}

export const fetchSingleBrandDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_BRAND_DATA_REQUESTED, payload };
}

export const fetchSingleBrandDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_BRAND_DATA_SUCCEEDED, payload };
}

export const fetchSingleBrandDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_BRAND_DATA_FAILED, payload };
}

//Categories
export const fetchCategoriesDataRequested = (payload) => {
  return { type: actionType.FETCH_CATEGORIES_DATA_REQUESTED, payload };
}

export const fetchCategoriesDataSucceeded = (payload) => {
  return { type: actionType.FETCH_CATEGORIES_DATA_SUCCEEDED, payload };
}

export const fetchCategoriesDataFailed = (payload) => {
  return { type: actionType.FETCH_CATEGORIES_DATA_FAILED, payload };
}

export const fetchSingleCategoryDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_CATEGORY_DATA_REQUESTED, payload };
}

export const fetchSingleCategoryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_CATEGORY_DATA_SUCCEEDED, payload };
}

export const fetchSingleCategoryDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_CATEGORY_DATA_FAILED, payload };
}

//Sub Categories
export const fetchSubCategoriesDataRequested = (payload) => {
  return { type: actionType.FETCH_SUB_CATEGORIES_DATA_REQUESTED, payload };
}

export const fetchSubCategoriesDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SUB_CATEGORIES_DATA_SUCCEEDED, payload };
}

export const fetchSubCategoriesDataFailed = (payload) => {
  return { type: actionType.FETCH_SUB_CATEGORIES_DATA_FAILED, payload };
}

export const fetchSingleSubCategoryDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_SUB_CATEGORY_DATA_REQUESTED, payload };
}

export const fetchSingleSubCategoryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_SUB_CATEGORY_DATA_SUCCEEDED, payload };
}

export const fetchSingleSubCategoryDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_SUB_CATEGORY_DATA_FAILED, payload };
}

//NewsLetter Email
export const fetchNewsLetterEmailsDataRequested = (payload) => {
  return { type: actionType.FETCH_NEWSLETTER_EMAILS_DATA_REQUESTED, payload };
}
export const fetchNewsLetterEmailsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_NEWSLETTER_EMAILS_DATA_SUCCEEDED, payload };
}

export const fetchNewsLetterEmailsDataFailed = (payload) => {
  return { type: actionType.FETCH_NEWSLETTER_EMAILS_DATA_FAILED, payload };
}

export const fetchNewsLetterEmailsDataDownloadRequested = (payload) => {
  return { type: actionType.FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_REQUESTED, payload };
}

export const fetchNewsLetterEmailsDataDownloadSucceeded = (payload) => {
  return { type: actionType.FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_SUCCEEDED, payload };
}

export const fetchNewsLetterEmailsDataDownloadFailed = (payload) => {
  return { type: actionType.FETCH_NEWSLETTER_EMAILS_DATA_DOWNLOAD_FAILED, payload };
}

// Product
export const fetchProductsDataRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_DATA_REQUESTED, payload };
}

export const fetchProductsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_DATA_SUCCEEDED, payload };
}

export const fetchProductsDataFailed = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_DATA_FAILED, payload };
}

export const fetchSingleProductDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_PRODUCT_DATA_REQUESTED, payload };
}

export const fetchSingleProductDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_PRODUCT_DATA_SUCCEEDED, payload };
}

export const fetchSingleProductDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_PRODUCT_DATA_FAILED, payload };
}

export const deleteProductDataRequest = (payload) => {
  return { type: actionType.DELETE_PRODUCT_REQUESTED, payload };
}

export const deleteProductDataSuccessed = (payload) => {
  return { type: actionType.DELETE_PRODUCT_SUCCEEDED, payload };
}

export const deleteProductDataFailed = (payload) => {
  return { type: actionType.DELETE_PRODUCT_FAILED, payload };
}

export const fetchActiveProductBySearchRequested = (payload) => {
  return { type: actionType.FETCH_ACTIVE_PRODUCTS_BY_SEARCH_REQUESTED, payload };
}
export const fetchActiveProductBySearchSuccess = (payload) => {
  return { type: actionType.FETCH_ACTIVE_PRODUCTS_BY_SEARCH_SUCCEEDED, payload };
}
export const fetchActiveProductBySearchFailed = (payload) => {
  return { type: actionType.FETCH_ACTIVE_PRODUCTS_BY_SEARCH_FAILED, payload };
}

export const fetchActivePackageProductRequested = (payload) => {
  return { type: actionType.FETCH_ACTIVE_PACKAGE_PRODUCTS_REQUESTED, payload };
}

export const fetchActivePackageProductSuccess = (payload) => {
  return { type: actionType.FETCH_ACTIVE_PACKAGE_PRODUCTS_SUCCEEDED, payload };
}

export const fetchActivePackageProductFailed = (payload) => {
  return { type: actionType.FETCH_ACTIVE_PACKAGE_PRODUCTS_FAILED, payload };
}

export const fetchProductsDataDownloadRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_DATA_DOWNLOAD_REQUESTED, payload };
}

export const fetchProductsDataDownloadSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED, payload };
}

//Stock Product
export const fetchStockProductsDataRequested = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_DATA_REQUESTED, payload };
}

export const fetchStockProductsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_DATA_SUCCEEDED, payload };
}

export const fetchStockProductsDataFailed = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_DATA_FAILED, payload };
}

export const fetchStockProductsDataDownloadRequested = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_REQUESTED, payload };
}

export const fetchStockProductsDataDownloadSucceeded = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_SUCCEEDED, payload };
}

export const fetchStockProductsDataDownloadFailed = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_DATA_DOWNLOAD_FAILED, payload };
}

export const fetchSingleStockProductDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_STOCK_PRODUCT_DATA_REQUESTED, payload };
}

export const fetchSingleStockProductDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_STOCK_PRODUCT_DATA_SUCCEEDED, payload };
}

export const fetchSingleStockProductDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_STOCK_PRODUCT_DATA_FAILED, payload };
}

export const fetchStockProductSinglePurchaseOrSalesDataRequested = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_REQUESTED, payload };
}

export const fetchStockProductSinglePurchaseOrSalesDataSucceeded = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_SUCCEEDED, payload };
}

export const fetchStockProductSinglePurchaseOrSalesDataFailed = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCT_SINGLE_PURCHASE_OR_SALES_DATA_FAILED, payload };
}

export const fetchStockProductsPurchaseOrSalesDataRequested = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_REQUESTED, payload };
}

export const fetchStockProductsPurchaseOrSalesDataSucceeded = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_SUCCEEDED, payload };
}

export const fetchStockProductsPurchaseOrSalesDataFailed = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_FAILED, payload };
}

export const fetchStockProductsPurchaseOrSalesDataDownloadRequested = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_REQUESTED, payload };
}

export const fetchStockProductsPurchaseOrSalesDataDownloadSucceeded = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_SUCCEEDED, payload };
}

export const fetchStockProductsPurchaseOrSalesDataDownloadFailed = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_PURCHASE_OR_SALES_DATA_DOWNLOAD_FAILED, payload };
}

export const fetchStockProductsTotalSummaryDataRequested = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_REQUESTED, payload };
}

export const fetchStockProductsTotalSummaryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_SUCCEEDED, payload };
}

export const fetchStockProductsTotalSummaryDataFailed = (payload) => {
  return { type: actionType.FETCH_STOCK_PRODUCTS_TOTAL_SUMMARY_DATA_FAILED, payload };
}

//Coupon
export const fetchCouponsDataRequested = (payload) => {
  return { type: actionType.FETCH_COUPONS_DATA_REQUESTED, payload };
}

export const fetchCouponsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_COUPONS_DATA_SUCCEEDED, payload };
}

export const fetchCouponsDataFailed = (payload) => {
  return { type: actionType.FETCH_COUPONS_DATA_FAILED, payload };
}

export const fetchSingleCouponDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_COUPON_DATA_REQUESTED, payload };
}

export const fetchSingleCouponDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_COUPON_DATA_SUCCEEDED, payload };
}

export const fetchSingleCouponDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_COUPON_DATA_FAILED, payload };
}

export const fetchActiveCouponBySearchRequested = (payload) => {
  return { type: actionType.FETCH_ACTIVE_COUPONS_BY_SEARCH_REQUESTED, payload };
}
export const fetchActiveCouponBySearchSuccess = (payload) => {
  return { type: actionType.FETCH_ACTIVE_COUPONS_BY_SEARCH_SUCCEEDED, payload };
}
export const fetchActiveCouponBySearchFailed = (payload) => {
  return { type: actionType.FETCH_ACTIVE_COUPONS_BY_SEARCH_FAILED, payload };
}

// Order
export const fetchOrdersDataRequested = (payload) => {
  return { type: actionType.FETCH_ORDERS_DATA_REQUESTED, payload };
}

export const fetchOrdersDataSucceeded = (payload) => {
  return { type: actionType.FETCH_ORDERS_DATA_SUCCEEDED, payload };
}

export const fetchOrdersDataFailed = (payload) => {
  return { type: actionType.FETCH_ORDERS_DATA_FAILED, payload };
}

export const fetchOrdersDataDownloadRequested = (payload) => {
  return { type: actionType.FETCH_ORDERS_DATA_DOWNLOAD_REQUESTED, payload };
}

export const fetchOrdersDataDownloadSucceeded = (payload) => {
  return { type: actionType.FETCH_ORDERS_DATA_DOWNLOAD_SUCCEEDED, payload };
}

export const fetchSingleOrderDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_ORDER_DATA_REQUESTED, payload };
}

export const fetchSingleOrderDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_ORDER_DATA_SUCCEEDED, payload };
}

export const fetchSingleOrderDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_ORDER_DATA_FAILED, payload };
}

export const removeByIdObject = (payload) => {
  return { type: actionType.REMOVE_BY_ID_OBJECT, payload };
}
export const fetchSalesDataDataRequested = (payload) => {
  return { type: actionType.FETCH_SALES_DATA_DATA_REQUESTED, payload };
}
export const fetchSalesDataDataSuccess = (payload) => {
  return { type: actionType.FETCH_SALES_DATA_DATA_SUCCESS, payload };
}
export const fetchSalesDataDataFailed = (payload) => {
  return { type: actionType.FETCH_SALES_DATA_DATA_FAILED, payload };
}

// Product Request
export const fetchProductRequestsDataRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCT_REQUESTS_DATA_REQUESTED, payload };
}

export const fetchProductRequestsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCT_REQUESTS_DATA_SUCCEEDED, payload };
}

export const fetchProductRequestsDataFailed = (payload) => {
  return { type: actionType.FETCH_PRODUCT_REQUESTS_DATA_FAILED, payload };
}

// Statistics
export const fetchDashboardDataRequested = (payload) => { return { type: actionType.FETCH_DASHBOARD_DATA_REQUESTED, payload }; }
export const fetchDashboardDataSucceeded = (payload) => { return { type: actionType.FETCH_DASHBOARD_DATA_SUCCEEDED, payload }; }
export const fetchDashboardDataFailed = (payload) => { return { type: actionType.FETCH_DASHBOARD_DATA_FAILED, payload }; }

export const fetchAdminSalesReportRequested = (payload) => { return { type: actionType.FETCH_ADMIN_SALES_REPORT_REQUESTED, payload }; }
export const fetchAdminSalesReportSucceeded = (payload) => { return { type: actionType.FETCH_ADMIN_SALES_REPORT_SUCCEEDED, payload }; }
export const fetchAdminSalesReportFailed = (payload) => { return { type: actionType.FETCH_ADMIN_SALES_REPORT_FAILED, payload }; }

export const downloadOrderInvoiceRequest = (payload) => {
  return { type: actionType.DOWONLOAD_INVOICE_PDF_REQUEST, payload };
}

export const downloadOrderInvoiceSuccess = (payload) => {
  return { type: actionType.DOWONLOAD_INVOICE_PDF_SUCCESS, payload };
}

export const downloadOrderInvoiceError = (payload) => {
  return { type: actionType.DOWONLOAD_INVOICE_PDF_ERROR, payload };
}

export const productDatatableRowCountChange = (payload) => {
  return { type: actionType.PRODUCT_DATATABLE_PAGE_SIZE, payload };
}

export const couponDatatableRowCountChange = (payload) => {
  return { type: actionType.COUPON_DATATABLE_PAGE_SIZE, payload };
}

export const orderDatatableRowCountChange = (payload) => {
  return { type: actionType.ORDER_DATATABLE_PAGE_SIZE, payload };
}

export const userDatatableRowCountChange = (payload) => {
  return { type: actionType.USER_DATATABLE_PAGE_SIZE, payload };
}

// History
export const fetchProductHistoryDataRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCT_HISTORY_DATA_REQUESTED, payload };
}

export const fetchCategoryHistoryDataRequested = (payload) => {
  return { type: actionType.FETCH_CATEGORY_HISTORY_DATA_REQUESTED, payload };
}

export const fetchSubCategoryHistoryDataRequested = (payload) => {
  return { type: actionType.FETCH_SUB_CATEGORY_HISTORY_DATA_REQUESTED, payload };
}

export const fetchCouponHistoryDataRequested = (payload) => {
  return { type: actionType.FETCH_COUPON_HISTORY_DATA_REQUESTED, payload };
}

export const fetchOrderHistoryDataRequested = (payload) => {
  return { type: actionType.FETCH_ORDER_HISTORY_DATA_REQUESTED, payload };
}

export const fetchProductHistoryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCT_HISTORY_DATA_SUCCEEDED, payload };
}
export const fetchCategoryHistoryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_CATEGORY_HISTORY_DATA_SUCCEEDED, payload };
}
export const fetchSubCategoryHistoryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SUB_CATEGORY_HISTORY_DATA_SUCCEEDED, payload };
}
export const fetchCouponHistoryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_COUPON_HISTORY_DATA_SUCCEEDED, payload };
}

export const fetchOrderHistoryDataSucceeded = (payload) => {
  return { type: actionType.FETCH_ORDER_HISTORY_DATA_SUCCEEDED, payload };
}

export const fetchProductHistoryDataFailed = (payload) => {
  return { type: actionType.FETCH_PRODUCT_HISTORY_DATA_FAILED, payload };
}
export const fetchCategoryHistoryDataFailed = (payload) => {
  return { type: actionType.FETCH_CATEGORY_HISTORY_DATA_FAILED, payload };
}
export const fetchSubCategoryHistoryDataFailed = (payload) => {
  return { type: actionType.FETCH_SUB_CATEGORY_HISTORY_DATA_FAILED, payload };
}
export const fetchCouponHistoryDataFailed = (payload) => {
  return { type: actionType.FETCH_COUPON_HISTORY_DATA_FAILED, payload };
}
export const fetchOrderHistoryDataFailed = (payload) => {
  return { type: actionType.FETCH_ORDER_HISTORY_DATA_FAILED, payload };
}

//Product Question

export const fetchSingleProductQuestionsDataRequested = (payload) => {
  return { type: actionType.FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_REQUESTED, payload };
}

export const fetchSingleProductQuestionsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_SUCCEEDED, payload };
}

export const fetchSingleProductQuestionsDataFailed = (payload) => {
  return { type: actionType.FETCH_SINGLE_PRODUCT_QUESTIONS_DATA_FAILED, payload };
}

export const fetchProductQuestionCountRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCT_QUESTION_COUNT_REQUESTED, payload };
}

export const fetchProductQuestionCountSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCT_QUESTION_COUNT_SUCCEEDED, payload };
}

export const fetchProductQuestionCountFailed = (payload) => {
  return { type: actionType.FETCH_PRODUCT_QUESTION_COUNT_FAILED, payload };
}

export const fetchProductsQuestionsDataRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_QUESTIONS_DATA_REQUESTED, payload };
}

export const fetchProductsQuestionsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_QUESTIONS_DATA_SUCCEEDED, payload };
}

export const fetchProductsQuestionsDataFailed = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_QUESTIONS_DATA_FAILED, payload };
}

// Product Review And Rating

export const fetchProductReviewsDataRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCT_REVIEWS_DATA_REQUESTED, payload };
}

export const fetchProductReviewsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCT_REVIEWS_DATA_SUCCEEDED, payload };
}

export const fetchProductReviewsDataFailed = (payload) => {
  return { type: actionType.FETCH_PRODUCT_REVIEWS_DATA_FAILED, payload };
}

export const fetchProductsReviewsRatingsDataRequested = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_REQUESTED, payload };
}

export const fetchProductsReviewsRatingsDataSucceeded = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_SUCCEEDED, payload };
}

export const fetchProductsReviewsRatingsDataFailed = (payload) => {
  return { type: actionType.FETCH_PRODUCTS_REVIEWS_RATINGS_DATA_FAILED, payload };
}

//Acount
export const fetchIncomeExpenseTypeNameDataRequested = (payload) => ({ type: actionType.FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_REQUESTED, payload });
export const fetchIncomeExpenseTypeNameDataSucceeded = (payload) => ({ type: actionType.FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_SUCCEEDED, payload });
export const fetchIncomeExpenseTypeNameDataFailed = (payload) => ({ type: actionType.FETCH_INCOME_EXPENSE_TYPE_NAME_DATA_FAILED, payload });

export const fetchIncomeOrExpenseDataRequested = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_DATA_REQUESTED, payload });
export const fetchIncomeOrExpenseDataSucceeded = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_DATA_SUCCEEDED, payload });
export const fetchIncomeOrExpenseDataFailed = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_DATA_FAILED, payload });

export const fetchIncomeOrExpenseDataDownloadRequested = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_REQUESTED, payload });
export const fetchIncomeOrExpenseDataDownloadSucceeded = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_SUCCEEDED, payload });
export const fetchIncomeOrExpenseDataDownloadFailed = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_DATA_DOWNLOAD_FAILED, payload });

export const fetchSingleIncomeOrExpenseDataRequested = (payload) => ({ type: actionType.FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_REQUESTED, payload });
export const fetchSingleIncomeOrExpenseDataSucceeded = (payload) => ({ type: actionType.FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_SUCCEEDED, payload });
export const fetchSingleIncomeOrExpenseDataFailed = (payload) => ({ type: actionType.FETCH_SINGLE_INCOME_OR_EXPENSE_DATA_FAILED, payload });

export const fetchIncomeOrExpenseSummaryDataRequested = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_REQUESTED, payload });
export const fetchIncomeOrExpenseSummaryDataSucceeded = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_SUCCEEDED, payload });
export const fetchIncomeOrExpenseSummaryDataFailed = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_FAILED, payload });

export const fetchIncomeOrExpenseSummaryDataDownloadRequested = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_REQUESTED, payload });
export const fetchIncomeOrExpenseSummaryDataDownloadSucceeded = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_SUCCEEDED, payload });
export const fetchIncomeOrExpenseSummaryDataDownloadFailed = (payload) => ({ type: actionType.FETCH_INCOME_OR_EXPENSE_SUMMARY_DATA_DOWNLOAD_FAILED, payload });

export const fetchOrderByInvoiceIdRequested = (payload) => ({ type: actionType.FETCH_ORDER_BY_INVOICE_ID_REQUESTED, payload });
export const fetchOrderByInvoiceIdSucceeded = (payload) => ({ type: actionType.FETCH_ORDER_BY_INVOICE_ID_SUCCEEDED, payload })
export const fetchOrderByInvoiceIdFailed = (payload) => ({ type: actionType.FETCH_ORDER_BY_INVOICE_ID_FAILED, payload });

export const deleteIncomeORExpenseRequested = (payload) => ({ type: actionType.DELETE_INCOME_OR_EXPENSE_REQUESTED, payload });
export const deleteIncomeORExpenseSucceeded = (payload) => ({ type: actionType.DELETE_INCOME_OR_EXPENSE_SUCCEEDED, payload });
export const deleteIncomeORExpenseFailed = (payload) => ({ type: actionType.DELETE_INCOME_OR_EXPENSE_FAILED, payload });

export const accountDatatableRowCountChange = (payload) => ({ type: actionType.ACCOUNT_DATATABLE_PAGE_SIZE, payload });

// Packages
export const fetchPackagesDataRequested = (payload) => { return { type: actionType.FETCH_PACKAGES_DATA_REQUESTED, payload }; }
export const fetchPackagesDataSucceed = (payload) => { return { type: actionType.FETCH_PACKAGES_DATA_SUCCEEDED, payload }; }
export const fetchPackagesDataFailed = (payload) => { return { type: actionType.FETCH_PACKAGES_DATA_FAILED, payload }; }
export const fetchSinglePackageDataRequested = (payload) => { return { type: actionType.FETCH_SINGLE_PACKAGE_DATA_REQUESTED, payload }; }
export const fetchSinglePackageDataSucceed = (payload) => { return { type: actionType.FETCH_SINGLE_PACKAGE_DATA_SUCCEEDED, payload }; }
export const fetchSinglePackageDataFailed = (payload) => { return { type: actionType.FETCH_SINGLE_PACKAGE_DATA_FAILED, payload }; }

//Clear Data By Type
export const removeDataByTypeArray = (payload) => {
  return { type: actionType.REMOVE_DATA_BY_TYPE_ARRAY, payload };
}
export const clearStateAfterUnmounting = (payload) => {
  return { type: actionType.CLEAR_STATE_AFTER_UNMOUNTING, payload };
}
//Clear Form Data By Type
export const removeFormDataByTypeObject = (payload) => {
  return { type: actionType.REMOVE_FORM_DATA_BY_TYPE_OBJECT, payload };
}