import { LOGIN_REQUESTED, LOGIN_FAILED, LOGIN_SUCCEEDED, REGISTRATION_FAILED, REGISTRATION_REQUESTED, REGISTRATION_SUCCEEDED, CREATE_VENDOR_FAILED, CREATE_VENDOR_REQUESTED, CREATE_VENDOR_SUCCEEDED, UPDATE_VENDOR_FAILED, UPDATE_VENDOR_REQUESTED, UPDATE_VENDOR_SUCCEEDED } from 'constants/actions';
import * as actionType from 'constants/actions';


export const loginFailed = (payload) => {
  return { type: LOGIN_FAILED, payload };
}

export const loginRequested = (payload) => {
  return { type: LOGIN_REQUESTED, payload };
}

export const loginSucceeded = (payload) => {
  return { type: LOGIN_SUCCEEDED, payload };
}

export const registrationFailed = (payload) => {
  return { type: REGISTRATION_FAILED, payload };
}

export const registrationRequested = (payload) => {
  return { type: REGISTRATION_REQUESTED, payload };
}

export const registrationSucceeded = (payload) => {
  return { type: REGISTRATION_SUCCEEDED, payload };
}

export const updateAdminDataRequested = (payload) => { return { type: actionType.UPDATE_ADMIN_DATA_REQUESTED, payload }; }
export const updateAdminDataSucceeded = (payload) => { return { type: actionType.UPDATE_ADMIN_DATA_SUCCEEDED, payload }; }
export const updateAdminDataFailed = (payload) => { return { type: actionType.UPDATE_ADMIN_DATA_FAILED, payload }; }

export const createAdminDataRequested = (payload) => { return { type: actionType.CREATE_ADMIN_DATA_REQUESTED, payload }; }
export const createAdminDataSucceeded = (payload) => { return { type: actionType.CREATE_ADMIN_DATA_SUCCEEDED, payload }; }
export const createAdminDataFailed = (payload) => { return { type: actionType.CREATE_ADMIN_DATA_FAILED, payload }; }

export const createVendorFailed = (payload) => {
  return { type: CREATE_VENDOR_FAILED, payload };
}

export const createVendorRequested = (payload) => {
  return { type: CREATE_VENDOR_REQUESTED, payload };
}

export const createVendorSucceeded = (payload) => {
  return { type: CREATE_VENDOR_SUCCEEDED, payload };
}

export const updateVendorFailed = (payload) => {
  return { type: UPDATE_VENDOR_FAILED, payload };
}

export const updateVendorRequested = (payload) => {
  return { type: UPDATE_VENDOR_REQUESTED, payload };
}

export const updateVendorSucceeded = (payload) => {
  return { type: UPDATE_VENDOR_SUCCEEDED, payload };
}

export const createBrandRequested = (payload) => {
  return { type: actionType.CREATE_BRAND_REQUESTED, payload };
}

export const createBrandSucceeded = (payload) => {
  return { type: actionType.CREATE_BRAND_SUCCEEDED, payload };
}

export const createBrandFailed = (payload) => {
  return { type: actionType.CREATE_BRAND_FAILED, payload };
}

export const updateBrandRequested = (payload) => {
  return { type: actionType.UPDATE_BRAND_REQUESTED, payload };
}

export const updateBrandSucceeded = (payload) => {
  return { type: actionType.UPDATE_BRAND_SUCCEEDED, payload };
}

export const updateBrandFailed = (payload) => {
  return { type: actionType.UPDATE_BRAND_FAILED, payload };
}

export const createCategoryRequested = (payload) => {
  return { type: actionType.CREATE_CATEGORY_REQUESTED, payload };
}

export const createCategorySucceeded = (payload) => {
  return { type: actionType.CREATE_CATEGORY_SUCCEEDED, payload };
}

export const createCategoryFailed = (payload) => {
  return { type: actionType.CREATE_CATEGORY_FAILED, payload };
}

export const updateCategoryRequested = (payload) => {
  return { type: actionType.UPDATE_CATEGORY_REQUESTED, payload };
}

export const updateCategorySucceeded = (payload) => {
  return { type: actionType.UPDATE_CATEGORY_SUCCEEDED, payload };
}

export const updateCategoryFailed = (payload) => {
  return { type: actionType.UPDATE_CATEGORY_FAILED, payload };
}

export const deleteCategoryBrandRequested = (payload) => {
  return { type: actionType.DELETE_CATEGORY_BRAND_REQUESTED, payload };
}

export const deleteCategoryBrandSucceeded = (payload) => {
  return { type: actionType.DELETE_CATEGORY_BRAND_SUCCEEDED, payload };
}

export const deleteCategoryBrandFailed = (payload) => {
  return { type: actionType.DELETE_CATEGORY_BRAND_FAILED, payload };
}

export const createSubCategoryRequested = (payload) => {
  return { type: actionType.CREATE_SUB_CATEGORY_REQUESTED, payload };
}

export const createSubCategorySucceeded = (payload) => {
  return { type: actionType.CREATE_SUB_CATEGORY_SUCCEEDED, payload };
}

export const createSubCategoryFailed = (payload) => {
  return { type: actionType.CREATE_SUB_CATEGORY_FAILED, payload };
}

export const updateSubCategoryRequested = (payload) => {
  return { type: actionType.UPDATE_SUB_CATEGORY_REQUESTED, payload };
}

export const updateSubCategorySucceeded = (payload) => {
  return { type: actionType.UPDATE_SUB_CATEGORY_SUCCEEDED, payload };
}

export const updateSubCategoryFailed = (payload) => {
  return { type: actionType.UPDATE_SUB_CATEGORY_FAILED, payload };
}

export const createProductRequested = (payload) => {
  return { type: actionType.CREATE_PRODUCT_REQUESTED, payload };
}

export const createProductSucceeded = (payload) => {
  return { type: actionType.CREATE_PRODUCT_SUCCEEDED, payload };
}

export const createProductFailed = (payload) => {
  return { type: actionType.CREATE_PRODUCT_FAILED, payload };
}

export const updateProductRequested = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_REQUESTED, payload };
}

export const updateProductSucceeded = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_SUCCEEDED, payload };
}

export const updateProductFailed = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_FAILED, payload };
}

export const updateProductAnswerRequested = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_ANSWER_REQUESTED, payload };
}

export const updateProductAnswerSucceeded = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_ANSWER_SUCCEEDED, payload };
}

export const updateProductAnswerFailed = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_ANSWER_FAILED, payload };
}

export const updateProductReviewRequested = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_REVIEW_REQUESTED, payload };
}

export const updateProductReviewRatingSucceeded = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_REVIEW_SUCCEEDED, payload };
}

export const updateProductReviewRatingFailed = (payload) => {
  return { type: actionType.UPDATE_PRODUCT_REVIEW_FAILED, payload };
}

export const createDuplicateProductRequest = (payload) => {
  return { type: actionType.CREATE_DUPLICATE_PRODUCT_REQUEST, payload };
}
export const createDuplicateProductSuccess = (payload) => {
  return { type: actionType.CREATE_DUPLICATE_PRODUCT_SUCCESS, payload };
}
export const createDuplicateProductFailed = (payload) => {
  return { type: actionType.CREATE_DUPLICATE_PRODUCT_FAILED, payload };
}

export const createStockProductRequested = (payload) => {
  return { type: actionType.CREATE_STOCK_PRODUCT_REQUESTED, payload };
}

export const createStockProductSucceeded = (payload) => {
  return { type: actionType.CREATE_STOCK_PRODUCT_SUCCEEDED, payload };
}

export const createStockProductFailed = (payload) => {
  return { type: actionType.CREATE_STOCK_PRODUCT_FAILED, payload };
}

export const updateStockProductRequested = (payload) => {
  return { type: actionType.UPDATE_STOCK_PRODUCT_REQUESTED, payload };
}

export const updateStockProductSucceeded = (payload) => {
  return { type: actionType.UPDATE_STOCK_PRODUCT_SUCCEEDED, payload };
}

export const updateStockProductFailed = (payload) => {
  return { type: actionType.UPDATE_STOCK_PRODUCT_FAILED, payload };
}

export const createStockProductPurchaseOrSalesRequested = (payload) => {
  return { type: actionType.CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED, payload };
}

export const createStockProductPurchaseOrSalesSucceeded = (payload) => {
  return { type: actionType.CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED, payload };
}

export const createStockProductPurchaseOrSalesFailed = (payload) => {
  return { type: actionType.CREATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED, payload };
}

export const updateStockProductPurchaseOrSalesRequested = (payload) => {
  return { type: actionType.UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED, payload };
}

export const updateStockProductPurchaseOrSalesSucceeded = (payload) => {
  return { type: actionType.UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED, payload };
}

export const updateStockProductPurchaseOrSalesFailed = (payload) => {
  return { type: actionType.UPDATE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED, payload };
}

export const deleteStockProductPurchaseOrSalesRequested = (payload) => {
  return { type: actionType.DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_REQUESTED, payload };
}

export const deleteStockProductPurchaseOrSalesSucceeded = (payload) => {
  return { type: actionType.DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_SUCCEEDED, payload };
}

export const deleteStockProductPurchaseOrSalesFailed = (payload) => {
  return { type: actionType.DELETE_STOCK_PRODUCT_PURCHASE_OR_SALES_DATA_FAILED, payload };
}

export const createCouponRequested = (payload) => {
  return { type: actionType.CREATE_COUPON_REQUESTED, payload };
}

export const createCouponSucceeded = (payload) => {
  return { type: actionType.CREATE_COUPON_SUCCEEDED, payload };
}

export const createCouponFailed = (payload) => {
  return { type: actionType.CREATE_COUPON_FAILED, payload };
}

export const updateCouponRequested = (payload) => {
  return { type: actionType.UPDATE_COUPON_REQUESTED, payload };
}

export const updateCouponSucceeded = (payload) => {
  return { type: actionType.UPDATE_COUPON_SUCCEEDED, payload };
}

export const updateCouponFailed = (payload) => {
  return { type: actionType.UPDATE_COUPON_FAILED, payload };
}

export const deleteCouponCategoryRequested = (payload) => {
  return { type: actionType.DELETE_COUPON_CATEGORY_REQUESTED, payload };
}

export const deleteCouponCategorySucceeded = (payload) => {
  return { type: actionType.DELETE_COUPON_CATEGORY_SUCCEEDED, payload };
}

export const deleteCouponCategoryFailed = (payload) => {
  return { type: actionType.DELETE_COUPON_CATEGORY_FAILED, payload };
}

export const deleteCouponSubCategoryRequested = (payload) => {
  return { type: actionType.DELETE_COUPON_SUB_CATEGORY_REQUESTED, payload };
}

export const deleteCouponSubCategorySucceeded = (payload) => {
  return { type: actionType.DELETE_COUPON_SUB_CATEGORY_SUCCEEDED, payload };
}

export const deleteCouponSubCategoryFailed = (payload) => {
  return { type: actionType.DELETE_COUPON_SUB_CATEGORY_FAILED, payload };
}

export const deleteCouponProductRequested = (payload) => {
  return { type: actionType.DELETE_COUPON_PRODUCT_REQUESTED, payload };
}

export const deleteCouponProductSucceeded = (payload) => {
  return { type: actionType.DELETE_COUPON_PRODUCT_SUCCEEDED, payload };
}

export const deleteCouponProductFailed = (payload) => {
  return { type: actionType.DELETE_COUPON_PRODUCT_FAILED, payload };
}

export const setStatusCouponUserRequested = (payload) => {
  return { type: actionType.SET_STATUS_COUPON_USER_REQUESTED, payload };
}

export const setStatusCouponUserSucceeded = (payload) => {
  return { type: actionType.SET_STATUS_COUPON_USER_SUCCEEDED, payload };
}

export const setStatusCouponUserFailed = (payload) => {
  return { type: actionType.SET_STATUS_COUPON_USER_FAILED, payload };
}

export const orderStatusChangeRequested = (payload) => { return { type: actionType.ORDER_STATUS_CHANGE_REQUESTED, payload }; }
export const orderStatusChangeSucceeded = (payload) => { return { type: actionType.ORDER_STATUS_CHANGE_SUCCEEDED, payload }; }
export const orderStatusChangeFailed = (payload) => { return { type: actionType.ORDER_STATUS_CHANGE_FAILED, payload }; }

export const orderPaymentStatusChangeRequested = (payload) => { return { type: actionType.ORDER_PAYMENT_STATUS_CHANGE_REQUESTED, payload }; }
export const orderPaymentStatusChangeSucceeded = (payload) => { return { type: actionType.ORDER_PAYMENT_STATUS_CHANGE_SUCCEEDED, payload }; }
export const orderPaymentStatusChangeFailed = (payload) => { return { type: actionType.ORDER_PAYMENT_STATUS_CHANGE_FAILED, payload }; }

export const orderPaymentMethodChangeRequested = (payload) => { return { type: actionType.ORDER_PAYMENT_METHOD_CHANGE_REQUESTED, payload }; }
export const orderPaymentMethodChangeSucceeded = (payload) => { return { type: actionType.ORDER_PAYMENT_METHOD_CHANGE_SUCCEEDED, payload }; }
export const orderPaymentMethodChangeFailed = (payload) => { return { type: actionType.ORDER_PAYMENT_METHOD_CHANGE_FAILED, payload }; }

export const orderDeliveredByChangeRequested = (payload) => { return { type: actionType.ORDER_DELIVERED_BY_CHANGE_REQUESTED, payload }; }
export const orderDeliveredByChangeSucceeded = (payload) => { return { type: actionType.ORDER_DELIVERED_BY_CHANGE_SUCCEEDED, payload }; }
export const orderDeliveredByChangeFailed = (payload) => { return { type: actionType.ORDER_DELIVERED_BY_CHANGE_FAILED, payload }; }

export const partialUpdateOrderRequested = (payload) => { return { type: actionType.PARTIAL_UPDATE_ORDER_REQUESTED, payload }; }
export const partialUpdateOrderSucceeded = (payload) => { return { type: actionType.PARTIAL_UPDATE_ORDER_SUCCEEDED, payload }; }
export const partialUpdateOrderFailed = (payload) => { return { type: actionType.PARTIAL_UPDATE_ORDER_FAILED, payload }; }

export const updateOrderRequested = (payload) => {
  return { type: actionType.UPDATE_ORDER_REQUESTED, payload };
}

export const updateOrderSucceeded = (payload) => {
  return { type: actionType.UPDATE_ORDER_SUCCEEDED, payload };
}

export const updateOrderFailed = (payload) => {
  return { type: actionType.UPDATE_ORDER_FAILED, payload };
}

export const deleteImageRequested = (payload) => {
  return { type: actionType.DELETE_IMAGE_REQUESTED, payload };
}

export const deleteImageSucceed = (payload) => {
  return { type: actionType.DELETE_IMAGE_SUCCEEDED, payload };
}

export const deleteImageFailed = (payload) => {
  return { type: actionType.DELETE_IMAGE_FAILED, payload };
}

export const checkCatSlugRequest = (payload) => {
  return { type: actionType.CHECK_EXISTING_SLUG_REQUESTED, payload };
}

export const checkCatSlugSucceed = (payload) => {
  return { type: actionType.CHECK_EXISTING_SLUG_SUCCEED, payload };
}

export const checkCatSlugFailed = (payload) => {
  return { type: actionType.CHECK_EXISTING_SLUG_FAILED, payload };
}

// Packages
export const createPackageRequest = (payload) => { return { type: actionType.CREATE_PACKAGE_REQUESTED, payload }; }
export const createPackageSucceed = (payload) => { return { type: actionType.CREATE_PACKAGE_SUCCEEDED, payload }; }
export const createPackageFailed = (payload) => { return { type: actionType.CREATE_PACKAGE_FAILED, payload }; }
export const updatePackageRequest = (payload) => { return { type: actionType.UPDATE_PACKAGE_REQUESTED, payload }; }
export const updatePackageSucceed = (payload) => { return { type: actionType.UPDATE_PACKAGE_SUCCEEDED, payload }; }
export const updatePackageFailed = (payload) => { return { type: actionType.UPDATE_PACKAGE_FAILED, payload }; }

// Product variant
export const addProductVariantRequest = (payload) => { return { type: actionType.ADD_PRODUCT_VARIANT_REQUEST, payload }; }
export const addProductVariantSuccess = (payload) => { return { type: actionType.ADD_PRODUCT_VARIANT_SUCCESS, payload }; }
export const addProductVariantFailed = (payload) => { return { type: actionType.ADD_PRODUCT_VARIANT_ERROR, payload }; }
export const updateProductVariantRequest = (payload) => { return { type: actionType.UPDATE_PRODUCT_VARIANT_REQUEST, payload }; }
export const updateProductVariantSuccess = (payload) => { return { type: actionType.UPDATE_PRODUCT_VARIANT_SUCCESS, payload }; }
export const updateProductVariantFailed = (payload) => { return { type: actionType.UPDATE_PRODUCT_VARIANT_ERROR, payload }; }

//Account
export const createIncomeORExpenseTypeNameRequested = payload => ({ type: actionType.CREATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED, payload });
export const createIncomeORExpenseTypeNameSucceeded = payload => ({ type: actionType.CREATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED, payload });
export const createIncomeORExpenseTypeNameFailed = payload => ({ type: actionType.CREATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED, payload });
export const updateIncomeORExpenseTypeNameRequested = payload => ({ type: actionType.UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_REQUESTED, payload });
export const updateIncomeORExpenseTypeNameSucceeded = payload => ({ type: actionType.UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_SUCCEEDED, payload });
export const updateIncomeORExpenseTypeNameFailed = payload => ({ type: actionType.UPDATE_INCOME_OR_EXPENSE_TYPE_NAME_FAILED, payload });
export const createIncomeORExpenseRequested = payload => ({ type: actionType.CREATE_INCOME_OR_EXPENSE_REQUESTED, payload });
export const createIncomeORExpenseSucceeded = payload => ({ type: actionType.CREATE_INCOME_OR_EXPENSE_SUCCEEDED, payload });
export const createIncomeORExpenseFailed = payload => ({ type: actionType.CREATE_INCOME_OR_EXPENSE_FAILED, payload });
export const updateIncomeORExpenseRequested = (payload) => ({ type: actionType.UPDATE_INCOME_OR_EXPENSE_REQUESTED, payload });
export const updateIncomeORExpenseSucceeded = (payload) => ({ type: actionType.UPDATE_INCOME_OR_EXPENSE_SUCCEEDED, payload });
export const updateIncomeORExpenseFailed = (payload) => ({ type: actionType.UPDATE_INCOME_OR_EXPENSE_FAILED, payload });