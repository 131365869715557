import { defaults } from "lodash";
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js';

const getSerialize = (obj) => {
	var str = [];
	for (var p in obj)
		if (obj.hasOwnProperty(p) && obj[p]) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return str.join("&");
}

const getTotalIncomeOrExpenseAmount = (item, type) => {   //Type means incomes or expenses
	return item[type] && item[type].length > 0
		? item[type].reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)
		: 0;
}

const getFormatDate = (date) => moment(date).format('DD-MM-YYYY h:mm A');

const getDateRange = (startDate, endDate) => `${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}`;

const getFormatFloatValues = (number, decimalPoints) => !Number.isInteger(number) ? number.toFixed(decimalPoints) : number;

const handleDeleteOperation = (data, handleAction, deleteIncomeOrExpense) => {
	Swal.fire({
		title: "Confirmation",
		html: `Are you sure to delete?`,
		showCancelButton: true,
		confirmButtonText: "Yes",
		cancelButtonText: "Cancel",
	}).then((result) => {
		if (result.value) {
			handleAction(deleteIncomeOrExpense, data);
		}
	});
}


export { getSerialize, getTotalIncomeOrExpenseAmount, getFormatDate, getDateRange, getFormatFloatValues, handleDeleteOperation };
