import { combineReducers } from 'redux';
import appReducer, { initialState as appState } from './app';
import authReducer, { initialState as authState } from './auth';
import dataReducer, { initialState as dataState } from './data';
import formsReducer, { initialState as formsState } from './forms';


export const initialState = {
  app: appState,
  auth: authState,
  data: dataState,
  forms: formsState,
};

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  data: dataReducer,
  forms: formsReducer,
});
