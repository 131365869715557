import { combineEpics } from 'redux-observable';
import { fetchUsersDataEpic, fetchVendorsDataEpic } from './data';
import * as dataEpic from './data';
import { loginEpic, registrationEpic, createVendorEpic, updateVendorEpic } from './forms';
import * as formEpic from './forms';


export default combineEpics(

  //Users
  fetchUsersDataEpic,
  dataEpic.fetchUsersDataDownloadEpic,
  dataEpic.fetchProductWiseUsersDataDownloadEpic,
  dataEpic.fetchUserByPhoneNumberEpic,
  dataEpic.fetchSingleUserDataEpic,
  loginEpic,
  registrationEpic,

  //NewsLetter
  dataEpic.fetchNewsLetterEmailsDataEpic,
  dataEpic.fetchNewsLetterEmailsDataDownloadEpic,

  //Vendors
  createVendorEpic,
  updateVendorEpic,
  fetchVendorsDataEpic,
  dataEpic.fetchOwnVendorEpic,

  //Brands
  dataEpic.fetchBrandsDataEpic,
  dataEpic.fetchSingleBrandDataEpic,
  formEpic.createBrandEpic,
  formEpic.updateBrandEpic,

  //Categories
  dataEpic.fetchCategoriesDataEpic,
  dataEpic.fetchSingleCategoryDataEpic,
  formEpic.createCategoryEpic,
  formEpic.updateCategoryEpic,
  formEpic.deleteCategoryBrandEpic,

  //Sub Categories
  dataEpic.fetchSubCategoriesDataEpic,
  dataEpic.fetchSingleSubCategoryDataEpic,
  formEpic.createSubCategoryEpic,
  formEpic.updateSubCategoryEpic,

  dataEpic.fetchProductsDataEpic,
  dataEpic.fetchSingleProductDataEpic,

  //Products Q&A, Reviews
  dataEpic.fetchSingleProductQuestionsDataEpic,
  dataEpic.fetchProductsQuestionsDataEpic,
  dataEpic.fetchProductQuestionCountEpic,
  dataEpic.fetchProductReviewsDataEpic,
  dataEpic.fetchProductsReviewsRatingsDataEpic,
  formEpic.createProductEpic,
  formEpic.updateProductEpic,
  formEpic.updateProductAnswerEpic,
  formEpic.updateProductReviewRatingEpic,

  //Stocks
  formEpic.createStockProductEpic,
  dataEpic.fetchStockProductsDataEpic,
  dataEpic.fetchStockProductsDataDownloadEpic,
  formEpic.updateStockProductEpic,

  dataEpic.fetchStockProductSinglePurchaseOrSalesDataEpic,

  dataEpic.fetchStockProductsPurchaseOrSalesDataEpic,
  dataEpic.fetchStockProductsPurchaseOrSalesDataDownloadEpic,
  formEpic.createStockProductPurchaseOrSalesEpic,
  formEpic.updateStockProductPurchaseOrSalesEpic,
  formEpic.deleteStockProductPurchaseOrSalesEpic,

  dataEpic.fetchSingleStockProductDataEpic,

  dataEpic.fetchStockProductsTotalSummaryDataEpic,
  //Coupons
  dataEpic.fetchCouponsDataEpic,
  dataEpic.fetchSingleCouponDataEpic,
  formEpic.createCouponEpic,
  formEpic.updateCouponEpic,
  formEpic.deleteCouponCategoryEpic,
  formEpic.deleteCouponSubCategoryEpic,
  formEpic.deleteCouponProductEpic,
  formEpic.setStatusCouponUserEpic,
  dataEpic.fetchActiveCouponsBySearchEpic,

  //Orders
  dataEpic.fetchOrdersDataEpic,
  dataEpic.fetchOrdersDataDownloadEpic,
  dataEpic.fetchSingleOrderDataEpic,
  formEpic.orderStatusChangeEpic,
  formEpic.updateOrderEpic,
  formEpic.deleteImageEpic,
  formEpic.checkCatSlugEpic,
  dataEpic.downloadOrderInvoiceEpic,
  formEpic.orderPaymentStatusChangeEpic,
  formEpic.orderPaymentMethodChangeEpic,
  formEpic.orderDeliveredByChangeEpic,
  formEpic.partialUpdateOrderEpic,

  //Products
  dataEpic.fetchProductRequestsDataEpic,
  dataEpic.deleteProductDataEpic,
  dataEpic.fetchProductsDataDownloadEpic,
  dataEpic.fetchActiveProductsBySearchEpic,
  formEpic.createDuplicateProductEpic,
  formEpic.createProductVariantEpic,
  formEpic.updateProductVariantEpic,

  //History
  dataEpic.fetchProductsHistoryDataEpic,
  dataEpic.fetchCategoryHistoryDataEpic,
  dataEpic.fetchSubCategoryHistoryDataEpic,
  dataEpic.fetchCouponHistoryDataEpic,
  dataEpic.fetchOrderHistoryDataEpic,

  //Packages
  dataEpic.fetchPackagesDataEpic,
  dataEpic.fetchSinglePackageDataEpic,
  formEpic.createPackageEpic,
  formEpic.updatePackageEpic,
  dataEpic.fetchActivePackageProductsEpic,

  //Admin
  formEpic.createAdminEpic,
  formEpic.updateAdminEpic,
  dataEpic.fetchAdminSalesReportEpic,

  //Account
  dataEpic.fetchIncomeExpenseTypeNameDataEpic,
  formEpic.createIncomeORExpenseTypeNameEpic,
  formEpic.updateIncomeORExpenseTypeNameEpic,

  dataEpic.fetchIncomeOrExpenseDataEpic,
  dataEpic.fetchIncomeOrExpenseDataDownloadEpic,
  dataEpic.fetchSingleIncomeOrExpenseDataEpic,
  formEpic.createIncomeORExpenseEpic,
  formEpic.updateIncomeORExpenseEpic,
  dataEpic.deleteIncomeORExpenseEpic,

  dataEpic.fetchIncomeOrExpenseSummaryDataEpic,
  dataEpic.fetchIncomeOrExpenseSummaryDataDownloadEpic,
  //Misc
  dataEpic.fetchDashboardDataEpic,
  dataEpic.fetchSalesDataEpic,
  dataEpic.fetchOrderByInvoiceIdEpic,
);